import React, { useEffect } from 'react';
import classes from './MainContent.module.css'
import Home from '../Pages/Home/Home';
import Auth from '../Pages/Auth/Auth';
import Logout from '../Pages/Auth/Logout';
import UserAccount from '../Pages/UserAccount/UserAccount';

import AboutUs from '../Pages/AboutUs/AboutUs';
import TermsAndConditions from '../Pages/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from '../Pages/PrivacyPolicy/PrivacyPolicy';
import * as links from '../NavBar/items';
import { Route, Switch, useLocation } from 'react-router-dom';
// import FaultyDevices from '../Pages/FaultyDevices/FaultyDevices'; 
import { Redirect } from 'react-router-dom';

import Deployments from '../Pages/Deployments/Deployments';
import FAQs from '../Pages/FAQs/FAQs';
import API from '../Pages/API/API';
import PollenProject from '../Pages/pollen_project/pollen_project';
import Crowdsourcingtwo from '../Pages/Crowdsourcing_2/Crowdsourcing_2';
import Loop3 from '../Pages/Deployments/Loop3/Loop3'

import PerceptualMap from '../Pages/PerceptualMap/PerceptualMap';
import LandingPage from '../Pages/LandingPage/LandingPage';

import CreateEditDevices from '../Pages/CreateEditDevices/CreateEditDevices';

import { useAnalytics } from 'use-analytics';


const MainContent = props => {

  let location = useLocation()
  const analytics = useAnalytics()

  useEffect(() => {analytics.page()}, [location]) 

  return(
    <div className = {classes.MainContent}>
      <Switch>
        <Route path={"/"} exact component={LandingPage} />
        <Route path={"/" + links.NAV_HOME} component={Home} />
        <Route path={"/about_us"} component={AboutUs} />
        <Route path={"/FAQs"} component={FAQs} />
        <Route path={"/API"} component={API} />
        <Route path={"/" + links.NAV_AUTH} component={Auth} />
        <Route path={"/" + links.NAV_LOGOUT} component={Logout} />
        <Route path={'/' + links.NAV_USER} component={UserAccount} />
        <Route path={"/" + links.NAV_TERMSANDCONDITIONS} component={TermsAndConditions} />
        <Route path={"/" + links.NAV_PRIVACYPOLICY} component={PrivacyPolicy} />
        <Route path={"/projects"} exact component={Deployments} />
        <Route path={"/projects/loop3"} component={Loop3} />
        <Route path={"/pp"} component={PollenProject} />
        <Route path={"/cstwo"} component={Crowdsourcingtwo} />
        <Route path={"/pm"} component={PerceptualMap} />
        <Route path={"/create_and_edit_devices"} component={CreateEditDevices} />
        <Route path='*'><Redirect to='/' /></Route>
      </Switch>
      <div id='bottom'> </div>
    </div>
  )

}

export default MainContent