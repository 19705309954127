import React from 'react';
import { debounce } from 'lodash';
import classes from './TermsAndConditions.module.css';
import Separator from '../../UI/Separator/separator';
 
 
class TermsAndConditions extends React.Component {

  resize = debounce(() => {this.forceUpdate()}, 200)


  componentDidMount() {
    window.addEventListener('resize', this.resize)
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  
  render() {

    return(
      <div className = {classes.pageContent}>
        {/* <Scroll /> */}
        <h1>Terms & Conditions</h1>
        <div className = {classes.tc}>
          <p class='tc'>These terms and conditions apply to the data made available through this website unless otherwise stated.</p>
          <h2>Definitions</h2>
          <p>In this licence, the terms below have the following meanings:</p>
          <ul>
            <li>'<b>Information</b>' means the data and source code offered for use under the terms of this licence and accessed from the sources described on this website.</li>
            <li>'<b>Information Provider</b>' means the person or organisation providing the Information under this licence.</li>
            <li>'<b>Licensor</b>' means any Information Provider offering Information under the terms of this licence.</li>
            <li>'<b>Use</b>' means doing any act which is restricted by copyright or database right, whether in the original medium or in any other medium, and includes without limitation distributing, copying, adapting, modifying as may be technically necessary to use it in a different mode or format.</li>
            <li>'<b>You</b>', '<b>you</b>' and '<b>your</b>' means the natural or legal person, or body of persons corporate or incorporate, acquiring rights in the Information (whether the Information is obtained directly from the Licensor or otherwise) under this licence.</li>
          </ul>
          <h2>Conditions</h2>
          <p>Use of the methods described on this website to access data (the ‘Information’) gathered as part of the Triangulum Project indicates your acceptance of the terms and conditions below.</p>
          <ol>
            <li>The Licensor grants you a worldwide, royalty-free, perpetual, non-exclusive licence to use the Information subject to the conditions below.</li>
            <li>This licence does not affect your freedom under fair dealing or fair use or any other copyright or database right exceptions and limitations.</li>
            <li>You are free to:
              <ul>
                <li>copy, publish, distribute and transmit the Information;</li>
                <li>adapt the Information;</li>
                <li>exploit the Information commercially and non-commercially for example, by combining it with other Information, or by including it in your own product or application.</li>
              </ul>
            </li>
            <li>You must (where you do any of the above):
              <ul>
                <li>acknowledge the source of the Information in your product or application by including an appropriate statement and where possible providing a link to this license.</li>
              </ul>
            </li>
          </ol>
          <p>These are important conditions of this licence and if you fail to comply with them the rights granted to you under this licence, or any similar licence granted by the Licensor, will end automatically.</p>

          <h2>No Warranty</h2>
          <p>The Information is licensed 'as is' and the Information Provider and/or Licensor excludes all representations, warranties, obligations and liabilities in relation to the Information to the maximum extent permitted by law.</p>
          <p>The Information Provider and/or Licensor are not liable for any errors or omissions in the Information and shall not be liable for any loss, injury or damage of any kind caused by its use. The Information Provider does not guarantee the continued supply of the Information.</p>

          <h2>Governing Law</h2>
          <p>This licence is governed by the laws of the jurisdiction in which the Information Provider has its principal place of business, unless otherwise specified by the Information Provider.</p>
        </div>
        <Separator direction='v' size='50'/>
      </div>
    );
  }
}

export default TermsAndConditions;



