import React from 'react';
import classes from './AboutUs.module.css';
import Separator from '../../UI/Separator/separator';
import { NavLink } from 'react-router-dom';
 

const AboutUs = props => {

  return(
    <div className = {classes.pageContent}>

      <h1>About Us</h1>
      <div className = {classes.tc}>
        <p>This is the new incarnation of <b>Manchester-i</b>, an open-data solution serving the city of Manchester and driven by research projects in the fields of Smart-Cities, IoT, Urban development and sustainability.</p>
        <p>The brand Manchester-i was originally introduced in the context of <a href="http://www.triangulum-project.eu/" target="blanck">Triangulum</a>, a EU funded project aiming to demonstrate and replicate smart-city solutions around the themes of energy, mobility and ICT. Since then, Manchester-i has been redesigned to leverage on the lessons learned during Triangulum, while meeting the need for a more user-friendly, rich and insightful data experience.</p>
        <h3>How to acknowledge us</h3>
        <p>To acknowledge use of data obtained through Manchester-i, please insert the following text into any resulting outputs (papers, chapters, reports, plots, etc.):</p>
        <p className = {classes.quotation}>
          “This data was provided by Manchester-i, a data solution that collects, hosts and exposes city open-data to a broad set of researchers and end-users operating / interested in urban related disciplines”.
        </p>
      </div>

      <Separator orientation='v' size='30' />

      <div style={{ display: "flex", width:"90%", margin: "auto", justifyContent: "center"}}>

        <NavLink
          to={'/terms_and_conditions'} exact
          className = {classes.navLink}
        >
          <img alt="" src={require('../../imgs/t&c.svg')} style={{ width: "2rem" }} />
          <Separator orientation='h' size='10' />
          Terms & Conditions
        </NavLink>

        <Separator orientation='h' size='30' />

        <NavLink
          to={'/privacy_policy'} exact
          className = {classes.navLink}
        >
          <img alt="" src={require('../../imgs/security.svg')} style={{ width: "2rem" }} />
          <Separator orientation='h' size='10' />
          Privacy Policy  
        </NavLink>
      </div>
      <Separator direction='v' size='50'/>
    </div>
    
  );

}

export default AboutUs;








