import React from 'react';
import  classes from "./tile.module.css"

const Tile = props => {

  return(
    <div className = {classes.visibleBox} >
      <div className = {classes.primary_content}>
        <div 
        style={{
          background: `url(${props.image})`, 
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top"
        }} 
        className = {classes.image}
        />
        <p className = {classes.title}>{props.title}</p>

        <p className={classes.description}>{props.description}</p>
          <p style={{padding:"0rem", margin:"0.7rem 0.5rem 0rem 0.5rem", fontSize: "0.85rem"}}>{props.startDate}  to  {props.endDate}</p>
          {/* <p style={{padding:"0rem", margin:"0.2rem 0.5rem 0rem 0.5rem", fontSize: "0.85rem"}}>Ended: {props.endDate}</p> */}
          

          <div className = {classes.exploreBtn}
          onClick={props.onClick}>
            Explore
          </div>
      
      </div>
    </div>
    
  );

}

export default Tile;