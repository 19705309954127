import React, { useState, useEffect, useContext, Fragment } from "react"
import { useHistory } from "react-router-dom"
import BarChartCust from './barChart'


// Global variables
import * as gv from "../../global_variables"

// Querying data
import axios from "axios"

import Baloon from "./Baloon"
import { Marker } from 'react-map-gl';

import MetaTags from "react-meta-tags"

import classes from "./PerceptualMap.module.css"
import Mapi from '../../UI/Map/map'
import AuthContext from "../../store/auth-context"

const PerceptualMap = props => {

  const authCtx = useContext(AuthContext)
  const isLoggedIn = authCtx.isLoggedIn

  const history = useHistory()

  const [metrics, setMetrics] = useState([])
  const [selectedMetric, setSelectedMetric] = useState(undefined)
  const [dataPoints, setDataPoints] = useState([])
  const [windowW, setWindowW] = useState(window.innerWidth)
  const [windowH, setWindowH] = useState(window.innerHeight)
  const [showBaloon, setShowBaloon] = useState(false)
  const [showLegend, setShowLegend] = useState(false)
  const [selDataPoint, setSelDataPoint] = useState(null)
  const [graphVisible, setGraphVisible] = useState(false)


  const barChartBlue = (
    <img
      alt=""
      src={require("../../imgs/show_chart.svg")}
      className={classes.graph}
    />
  )

  const months = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec",
  };

  //======================================

  const getPerceptualMetrics = async () => {

    const application = props.history.location.application ? props.history.location.application : 'hys';
    const res = await axios.get(
      `${gv.backend_url}/perc_metrics/${application}`
    );

    const metrics = res.data.sort((a, b) => {
      const metric1 = a.applications.filter(
        (app) => app.name === application
      )[0];
      const metric2 = b.applications.filter(
        (app) => app.name === application
      )[0];
      if (metric1.order < metric2.order) {
        return -1;
      } else {
        return 1;
      }
    });
    metrics.map((m) => {
      m.icon = require(`../../imgs/${m.icon}`);
      return m;
    });
    await setMetrics(metrics);
  }

  useEffect(() => {

    // Handler to call on window resize
    function handleResize() {
      setWindowW(window.innerWidth)
      setWindowH(window.innerHeight)
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    getPerceptualMetrics();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);

  }, []);


  useEffect(() => {
    createGraph()
  }, [dataPoints])


  const showChart = (
    <div
      className={classes.info}
      onClick={() => showGraph()}
    >
      {barChartBlue}
    </div>
  );


  const getPerceptualData = async metric => {
    const res = await axios.get(`${gv.backend_url}/perception`, {
      params: { metric_id: metric },
    });
    await setDataPoints(res.data)
  }

  const createGraph = async () => {
  }



  const handleSelect = async (e) => {
    await setDataPoints([])
    await setSelDataPoint(null)
    await setShowBaloon(false)
    if (e.target.selectedIndex > 0) {
      await setSelectedMetric(metrics[e.target.selectedIndex - 1])
      getPerceptualData(metrics[e.target.selectedIndex - 1].id);
    } else {
      await setSelectedMetric(null)
    }
  }

  const closeLegend = async () => {
    await setShowLegend(false);
  }

  const openLegend = async () => {
    await setShowLegend(true);
  }

  const doSomething = async (e, dp) => {
    await setSelDataPoint(dp)
    await setShowBaloon(true)
  }

  const mapClick = e => {
    setSelDataPoint(null)
    setShowBaloon(false)
  }

  const formatDate = timestamp => {
    const dateObj = new Date(timestamp)
    return `${dateObj.getDate()}  ${months[dateObj.getMonth()]
      } ${dateObj.getFullYear()}`
  }


  //==============================


  let uriPattern = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/g;
  let comment = ''

  if (selDataPoint && selDataPoint.comment) {
    comment = selDataPoint.comment
    let output = comment.match(uriPattern);
    if (output !== null) {
      for (let i = 0; i < output.length; i++) {
        comment = comment.replace(output[i], '<a href="' + output[i] + '" target="_blank">' + output[i] + '</a>')
      }
    }
  }


  const baloon =
    <Marker
      longitude={selDataPoint ? selDataPoint.location.longitude : 0}
      latitude={selDataPoint ? selDataPoint.location.latitude : 0}
      // anchor = {selDataPoint ? [selDataPoint.location.latitude, selDataPoint.location.longitude] : [0,0]}
      // offset={[0, 0]}
      style={{ display: showBaloon ? "" : "none" }}
    >

      <Baloon
        title={selDataPoint
          ? selectedMetric.answers[selDataPoint.value].text
          : ""
        }
        comment={comment}
        timestamp={selDataPoint
          ? formatDate(selDataPoint.timestamp)
          : ""}
        image={selDataPoint && selDataPoint.image ?
          <img alt="" src={gv.backend_url + '/perception/' + selDataPoint['_id'] + '/image'} />
          :
          ''}
        click={mapClick}
      ></Baloon>
    </Marker>


  const select_metric = metrics.length ? (
    <select
      className={classes.selectMetric}
      onChange={(e) => {
        handleSelect(e);
      }}
      defaultValue="Show results for... "
    >
      <option
        key={-1}
        id={-1}
        label="Show results for..."
        name="Show results for..."
        value=""
      >
        Select the type of report...
      </option>

      {metrics.map((metric, index) => {
        return (
          <option
            key={index}
            id={metric.id}
            label={metric.shortQuestion ? (metric.type + ' - ' + metric.shortQuestion) : metric.question}
            name={metric.id}
            value={metric.id}
          >
            {metric.shortQuestion ? (metric.type + ' - ' + metric.shortQuestion) : metric.question}
          </option>
        );
      })}
    </select>
  ) : (
    ""
  );

  const showHideLegend = (
    <div className={classes.showLegend} onClick={openLegend}>
      <img
        alt=""
        src={require("../../imgs/show_legend.svg")}
        style={{ width: "1.3rem" }}
      />
    </div>
  );

  const closeGraph = () => {
    setGraphVisible(false)
  }

  const showGraph = () => {
    setGraphVisible(true)
  }


  const barChart = graphVisible
    ? <BarChartCust
      metric={selectedMetric}
      dataPoints={dataPoints}
      onClose={closeGraph}
      colors={selectedMetric && selectedMetric.answers.map(a => a.colour)}
    />
    : null



  let legend =
    selectedMetric && showLegend ? (
      <div className={classes.legend}>
        <div
          style={{
            position: "absolute",
            top: "0.3rem",
            right: "0.45rem",
            transform: "ranslate(50%,50%)",
            cursor: "pointer",
          }}
          className={classes.closeCross}
          onClick={closeLegend}
        >
          <span
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              textAlign: "center",
              fontSize: "2rem",
              lineHeight: "1.5rem",
              color: "grey",
            }}
          >
            ×
          </span>
        </div>

        {selectedMetric.answers.map((a, index) => {
          return (
            <div
              key={index}
              style={{
                padding: "0.2rem 0rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "0.7rem",
                  height: "0.7rem",
                  backgroundColor: a.colour,
                  borderRadius: "50%",
                  margin: "0rem 0.7rem 0rem 0rem",
                }}
              ></div>
              <div style={{ textAlign: "left" }}>{a.text}</div>
            </div>
          );
        })}
      </div>
    ) : (
      ""
    );



  const pins = dataPoints.map((dp, index) => {
    return (
      <Marker
        key={index}
        longitude={dp.location.longitude}
        latitude={dp.location.latitude}
        captureClick={false}
      >
        <div
          style={{
            width: "0.7rem",
            height: "0.7rem",
            cursor: "pointer",
            backgroundColor: selectedMetric
              ? selectedMetric.answers[dp.value].colour
              : "grey",
            transform: "translate(-50%, -50%)",
            borderRadius: "50%",
          }}
          onClick={(e) => doSomething(e, dp)}
        />
      </Marker>
    );
  });

  let width =
    windowW > 1000
      ? windowW - 12 * 16 - 8.3 * 16
      : windowW;
  let height =
    windowW > 1000
      ? windowH - 4 * 16 - 0.3 * 16
      : windowH;


  const all = (
    <Fragment>
      {!isLoggedIn && history.replace({ pathname: `/authenticate`, provenance: 'pm' })}
      <MetaTags>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0"
        />
        <meta
          name="display"
          content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0"
        />
      </MetaTags>
      <Mapi
        height={height}
        width={width}
        datapoints={pins}
        baloon={baloon}
        freezeMap={showBaloon}
        target={true}
        showPinOnClick={false}
        mapStyle='mapbox://styles/ettoremurabito/cky4yo7nj5s2f15pcivxqchw2'
        zoomOnClick={true}
        centerOnClick={true}
      />
      {select_metric}
      {showHideLegend}
      {showChart}
      {legend}
      {barChart}


    </Fragment>
  );

  return all;

}


export default PerceptualMap
