import React, { useEffect, useState, useContext, Fragment } from "react"
import { useHistory } from "react-router-dom"
import axios from "axios"
import ButtonBar from "../../UI/ButtonBar/ButtonBar"
import classes from './CreateEditDevices.module.css'
import * as gv from "../../global_variables"
import CheckBoxArea from "../../UI/CheckBoxArea/CheckBoxArea"
import AuthContext from "../../store/auth-context"
import DevInfo from "./DevInfo"

const CreateEditDevices = props => {

  const authCtx = useContext(AuthContext)
  const isLoggedIn = authCtx.isLoggedIn

  const history = useHistory()

  const [devDep, setDevDep] = useState(undefined)
  const [crtEdt, setCrtEdt] = useState(undefined)
  const [showDepDev, setShowDepDev] = useState(false)
  const [showCrtDev, setShowCrtDev] = useState(false)
  const [showEdtDev, setShowEdtDev] = useState(false)
  const [showCrtDep, setShowCrtDep] = useState(false)
  const [devBrand, setDevBrand] = useState(null)
  const [devIdNum, setdevIdNum] = useState(null)
  const [inDeployment, setInDeployment] = useState(new Set([]))
  const [observables, setObservables] = useState([])
  const [deployments, setDeployments] = useState([])
  // const [deviceQualifier, setDeviceQualifier] = useState('')
  // const [device, setDevice] = useState(false)
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [address, setAddress] = useState('')
  // const [searchDevice, setSearchDevice] = useState('')
  const [searchResult, setSearchResult] = useState([])
  // const [devType, setDevType] = useState(null)
  const [visibility, setVisibility] = useState(null)
  // const [selDspls, setSelDspls] = useState([])
  const [log, setLog] = useState([])
  const [working, setWorking] = useState(false)
  const [saveClicked, setSaveClicked] = useState(false)
  const [outbound, setOutbound] = useState(null)
  const [inbound, setInbound] = useState(null)
  // const [depLabel, setDepLabel] = useState('')
  const [depId, setDepId] = useState('')
  const [depDesc, setDepDesc] = useState('')
  const [depUrl, setDepUrl] = useState('')

  const config = {
    headers: {
      Authorization: "Bearer " + authCtx.token
    }
  }

  useEffect(async () => {
    await loadObservables()
    await loadDeployments()
  }, [])

  useEffect(() => {
    loadDevice()
    setInbound(null)
    setOutbound(null)
  }, [devBrand])

  useEffect(() => {
    setShowDepDev(crtEdt !== undefined ? true : false)
    showDetails()
  }, [crtEdt])

  useEffect(() => {
    showDetails()
  }, [devDep])

  useEffect(() => {
    setSaveClicked(false)
  }, [devBrand, devIdNum, inDeployment,
    latitude, longitude, address,
    visibility, depId, depDesc, depUrl])

  useEffect(() => {
  }, [log])


  //---- GLOBAL VARIABLES ----

  let log1 = []

  const devDepButtonsLabels = ['Device', 'Project']
  const crtEdtButtonsLabels = ['Create', 'Edit']
  const brands = Object.keys(DevInfo)
  const visibilityOptions = ['Everyone', 'Superusers', 'Administrators', 'Demiurge']


  //---- FUNCTIONS ----

  const loadObservables = async () => {
    const res = await axios.get(`${gv.backend_url}/mongo/observable-properties`, config)
    let obs = res.data.map(o => {
      return ({ id: o['@id'], label: o.label, cumulative: o.cumulative })
    })
    obs.sort((a, b) => (
      a.label.toLowerCase() > b.label.toLowerCase())
      ? 1 : ((b.label.toLowerCase() > a.label.toLowerCase())
        ? -1 : 0
      )
    )
    setObservables(obs)
  }


  const loadDeployments = async () => {
    const res = await axios.get(`${gv.backend_url}/mongo/deployments`, config)
    let depls = res.data.map(d => { return ({ id: d.id, label: d.label }) })
    depls.sort((a, b) => (
      a.label.toLowerCase() > b.label.toLowerCase())
      ? 1 : ((b.label.toLowerCase() > a.label.toLowerCase())
        ? -1 : 0
      )
    )
    setDeployments(depls)
  }


  const handleChangeGeoLocation = async (e) => {
    e.target.id === 'latitude'
      ? setLatitude(e.target.value)
      : e.target.id === 'longitude'
        ? setLongitude(e.target.value)
        : setAddress(e.target.value)
  }


  const handleSearchDeviceChange = async (e) => {
    // setSearchDevice(e)
  }


  const createDeployment = async () => {

    // const newDep =
    // {
    //   id: depId,
    //   label: depLabel,
    //   description: depDesc,
    //   startDate: new Date(),
    //   deployedOnPlatform: [],
    //   externalLink: depUrl,
    //   visibility: Number(visibility)
    // }
  
    try {
      // const d = await axios.post(`${gv.backend_url}/mongo/deployments`, newDep, config)
      log1.push({ message: `Create deployment ${depId} --> DONE`, color: `darkgreen`, status: `success` })
      
    }
    catch (e) {
      log1.push({ message: `Create deployment ${depId} --> FAILED`, color: `darkred`, status: `failed` })
    }
  }


  const cratePlatform = async (device_id) => {
    const platform = {
      id: device_id,
      manufacturer: devBrand,
      serialNumber: devIdNum,
      model: null,
      label: `${DevInfo[devBrand].label} ${devIdNum}`,
      description: DevInfo[devBrand].type,
      static: true,
      visibility: Number(visibility),
      hosts: DevInfo[devBrand].sensors.map(o => `${device_id}__${Object.keys(o)[0]}`)
    }
    try {
      await axios.post(`${gv.backend_url}/createplatform`, platform, config)
      return platform
    }
    catch (e) {
      return null
    }
  }


  const createLocation = async (device_id) => {
    let loc = {
      platform: device_id,
      locations: [...inDeployment].map(d => {
        return (
          {
            coordinates: [
              longitude,
              latitude
            ],
            inDeployment: d.id,
            startDate: new Date(),
            endDate: null,
            address
          }
        )
      })
    }
    try {
      await axios.post(`${gv.backend_url}/createlocation`, loc, config)
      return loc
    }
    catch (e) {
      return null
    }
  }

  const updateDeployments = async (device_id) => {
    inDeployment.forEach(async d => {
      try {
        const res = await axios.get(`${gv.backend_url}/mongo/deployments/${d.id}`, config)
        let deployedOnPlatform = new Set(res.data.deployedOnPlatform)
        deployedOnPlatform.add(device_id)
        res.data.deployedOnPlatform = [...deployedOnPlatform]

        console.log(res.data)

        await axios.patch(`${gv.backend_url}/mongo/deployments/`, res.data, config)
        log1.push({ message: `Add device to deployment ${d.id}: DONE`, color: `darkgreen`, status: `success` })
      }
      catch (e) {
        log1.push({ message: `Add device to deployment ${d.id}: FAILED`, color: `darkred`, status: `failed` })
      }
    })
  }

  const createSensors = async (platform) => {
    DevInfo[devBrand].sensors.forEach(s => {
      const sensor_id = Object.keys(s)[0]
      const label = s[sensor_id].label
      const timeseries = s[sensor_id].observables.map(obs => {
        return obs.qualifier ? `${platform.id}__${obs.qualifier.id}` : `${platform.id}__${obs.id}`
      })
      const disciplines = new Set()
      s[sensor_id].observables.forEach(obs => {
        obs.disciplines.forEach(d => {
          if (!disciplines.has(d)) { disciplines.add(d) }
        })
      })
      let sensor = {
        id: `${platform.id}__${sensor_id}`,
        label,
        description: label,
        disciplines: [...disciplines],
        isHostedBy: platform.id,
        timeseries
      }
      try {
        axios.post(`${gv.backend_url}/createsensor`, sensor, config)
        log1.push({ message: `Create sensor ${platform.id}__${sensor_id} --> DONE`, color: 'darkgreen' })
      }
      catch (e) {
        log1.push({ message: `Create sensor ${platform.id}__${sensor_id} --> FAILED`, color: 'darkred' })
      }
    })
  }

  const createTimeseries = async (device_id) => {
    DevInfo[devBrand].sensors.forEach(s => {
      const sns_id = Object.keys(s)[0]
      s[sns_id].observables.forEach(async o => {
        const obs = observables.filter(o2 => o2.id === o.id)[0]
        const comment = o.qualifier ? o.qualifier.label : obs.label
        const id = o.qualifier ? `${device_id}__${o.qualifier.id}` : `${device_id}__${obs.id}`

        let timeseries = {
          id,
          observedProperty: o.id,
          comment,
          unit: o.unit,
          timeInterval: s[sns_id].timeInterval,
          madeBySensor: `${device_id}__${sns_id}`,
          // hasFeatureOfInterest: ro.hasFeatureOfInterest,
          disciplines: o.discipline,
          usedProcedures: o.usedProcedures,
          cumulative: obs.cumulative
        }

        if (inbound) {
          timeseries.id = `${id}_${inbound.id}`
          timeseries.comment = comment + " " + inbound.label
          try {
            await axios.post(`${gv.backend_url}/createtimeseries`, timeseries, config)
            log1.push({ message: `Create timeseries ${timeseries.id} --> DONE`, color: 'darkgreen' })
          }
          catch (e) {
            log1.push({ message: `Create timeseries ${timeseries.id} --> FAILED`, color: 'darkred' })
          }
        }
        if (outbound) {
          timeseries.id = `${id}_${outbound.id}`
          timeseries.comment = comment + " " + outbound.label
          try {
            await axios.post(`${gv.backend_url}/createtimeseries`, timeseries, config)
            log1.push({ message: `Create timeseries ${timeseries.id} --> DONE`, color: 'darkgreen' })
          }
          catch (e) {
            log1.push({ message: `Create timeseries ${timeseries.id} --> FAILED`, color: 'darkred' })
          }
        }
        if (!(inbound && outbound)) {
          try {
            await axios.post(`${gv.backend_url}/createtimeseries`, timeseries, config)
            log1.push({ message: `Create timeseries ${timeseries.id} --> DONE`, color: 'darkgreen' })
          }
          catch (e) {
            log1.push({ message: `Create timeseries ${timeseries.id} --> FAILED`, color: 'darkred' })
          }
        }
      })

    })
  }

  const updateLog = l => {
    setLog(l)
  }

  const handleSave = async (e) => {

    setWorking(true)
    log1 = []

    let platform = null
    let location = null
    // let deploUpd = null

    if (crtEdt === 'Create' && devDep === 'Device') {

      const device_id = `${devBrand.toLowerCase()}__${devIdNum}`
      platform = await cratePlatform(device_id)

      if (platform) {
        log1.push({ message: `Create Device ${device_id} --> DONE`, color: `darkgreen`, status: `success` })
        location = await createLocation(device_id)
      }
      else {
        log1.push({ message: `Create Device ${device_id} --> FAILED`, color: `darkred`, status: `failed` })
      }

      if (location) {
        log1.push({ message: `Create Location for ${device_id} --> DONE`, color: `darkgreen`, status: `success` })
        await updateDeployments(device_id)
      }
      else {
        log1.push({ message: `Create Location for ${device_id} --> FAILED`, color: `darkred`, status: `failed` })
      }

      if (platform && location) {
        // Crete sensors
        await createSensors(platform)
        // Create timeseries
        await createTimeseries(device_id)
      }

      // execute commands to start dataflow
      if (platform) {
        let params = {devbrand: devBrand, devidnum: devIdNum}
        if (inbound) params.inbound = inbound.id
        if (outbound) params.inbound = outbound.id
        try {
          await axios.post(`${gv.backend_url}/command`, params, config)
          log1.push({ message: `Establish dataflow for device ${device_id} --> DONE`, color: `darkgreen`, status: `success` })
        }
        catch (e) {
          log1.push({ message: `Establish dataflow for device ${device_id} --> FAILED`, color: `darkred`, status: `failed` })
        }
      }
    }

    if (crtEdt === 'Create' && devDep === 'Project') {
      await createDeployment()
    }

    updateLog(log1)
    setWorking(false)
    setSaveClicked(true)
  }


  const pippo = async (e) => {
    setCrtEdt(crtEdtButtonsLabels[e])
  }


  const pippo2 = async (e) => {
    setDevDep(devDepButtonsLabels[e])
  }


  const showDetails = async () => {
    if (devDep === "Device" && crtEdt === "Create") {
      setShowCrtDev(true)
      setShowEdtDev(false)
      setShowCrtDep(false)
    }
    if (devDep === "Device" && crtEdt === "Edit") {
      setShowCrtDev(false)
      setShowEdtDev(true)
      setShowCrtDep(false)
    }
    if (devDep === "Project" && crtEdt === "Create") {
      setShowCrtDev(false)
      setShowEdtDev(false)
      setShowCrtDep(true)
    }
    if (devDep === "Project" && crtEdt === "Edit") {
      setShowCrtDev(false)
      setShowEdtDev(true)
      setShowCrtDep(false)
    }
  }


  const handleDevBrand = async (brand) => {
    setDevBrand(brand.target.value)
  }


  const handleDeviceId = async (deviceId) => {
    setdevIdNum(deviceId.target.value)
  }


  const handleDeviceQualifier = async (e) => {
    // setDeviceQualifier(e.target.value)
  }


  const handleChangeDepsList = async (dpl) => {
    let dpls = new Set(inDeployment)
    if (dpls.has(dpl)) dpls.delete(dpl)
    else dpls.add(dpl)
    setInDeployment(dpls)
  }


  const handleInbound = async (dir) => {
    const id = dir.target.value
    const label = DevInfo[devBrand].duplicate.filter(d => d.id === dir.target.value)[0].label
    setInbound({ id, label })
  }

  const handleOutbound = async (dir) => {
    const id = dir.target.value
    const label = DevInfo[devBrand].duplicate.filter(d => d.id === dir.target.value)[0].label
    setOutbound({ id, label })
  }


  const loadDevice = async () => {
    console.log(DevInfo[devBrand])
    // setDevice(DevInfo[devBrand])
  }


  const handleVisibility = async (e) => {
    await setVisibility(e.target.value)
  }


  const handleSelectDevice = async (e, dev) => {
    console.log(dev)
    const device = await axios.get(`${gv.backend_url}/platforms/${dev}`, config)
    console.log(device.data)
  }


  const handleDepId = async (e) => {
    // setDepLabel(e.target.value)
    setDepId(e.target.value.toLowerCase().replaceAll(' ', '_'))
  }

  const handleDepDesc = async (e) => {
    setDepDesc(e.target.value)
  }

  const handleDepUrl = async (e) => {
    setDepUrl(e.target.value.toLowerCase().replaceAll(' ', ''))
  }


  //---- TITLES ----

  const title1 = `Which action do you want to undertake?`
  const title2 = `What do you want to ${crtEdt ? crtEdt.toLowerCase() : ''}?`
  const crtDevTitle = `Device's details`
  const crtDepTitle = `Project's details`


  //---- JSX ELEMENTS ----

  const crtEdtBtnsJSX = <ButtonBar buttons={crtEdtButtonsLabels} enabled={[1, 0]} selected={(e) => pippo(e)} />
  const devDepBtnsJSX = <ButtonBar buttons={devDepButtonsLabels} selected={(e) => pippo2(e)} />


  const devBrandJSX =
    <select
      className={classes.customInput}
      onChange={(e) => handleDevBrand(e)}
      defaultValue={null}
    >
      <option key={-1} label="Select brand *" value=''>Select brand *</option>
      {brands.map((b, idx) => <option key={idx} id={b} label={b} value={b}>{b}</option>)}
    </select>


  const deviceIdJSX = <input
    className={classes.customInput}
    type='text'
    onChange={e => handleDeviceId(e)}
    placeholder='Device id number *'
  />


  const deviceQual = <input
    className={classes.customInput}
    type='text'
    onChange={e => handleDeviceQualifier(e)}
    placeholder='comment (e.g. outdoor)'
  />


  const selInboundJSX = devBrand && DevInfo[devBrand].duplicate &&
    <select
      className={classes.customInput}
      onChange={(e) => handleInbound(e)}
      defaultValue={null}
    >
      <option key={-1} label="Inbound direction... *" value=''>Inbound direction... *</option>
      {DevInfo[devBrand].duplicate.map((d, idx) => <option key={idx} id={d.id} label={d.label} value={d.id}>{d.label}</option>)}
    </select>

  const selOutboundJSX = devBrand && DevInfo[devBrand].duplicate &&
    <select
      className={classes.customInput}
      onChange={(e) => handleOutbound(e)}
      defaultValue={null}
    >
      <option key={-1} label="Outbound direction... *" value=''>Outbound direction... *</option>
      {DevInfo[devBrand].duplicate.map((d, idx) => <option key={idx} id={d.id} label={d.label} value={d.id}>{d.label}</option>)}
    </select>


  const visibilityJSX =
    <select
      className={classes.customInput}
      onChange={(e) => handleVisibility(e)}
    >
      <option key={-1} id={-1} label="Visible to... *" value='' >
        Visible to... *
      </option>
      {visibilityOptions.map((v, index) => {
        return (
          <option key={index} id={v} label={v} value={index}>
            {v}
          </option>
        )
      })}
    </select>

  const location = <div style={{ flexFlow: 'column', width: '22rem' }}>
    <input className={classes.customInput} id='latitude' type='text' placeholder="Latitude (°) *" onChange={e => handleChangeGeoLocation(e)} />
    <div style={{ height: '0.5rem' }} />
    <input className={classes.customInput} id='longitude' type='text' placeholder="Longitude (°) *" onChange={e => handleChangeGeoLocation(e)} />
    <div style={{ height: '0.5rem' }} />
    <input className={classes.customInput} id='address' type='text' placeholder="Address *" onChange={e => handleChangeGeoLocation(e)} />
  </div>

  const dplsJSX = <CheckBoxArea items={deployments} onChange={handleChangeDepsList} />


  let srcResults = searchResult.map((obs, i) => {
    const dpl_id = obs.inDeployment.match(/\/([^\/]*)$/) ? obs.inDeployment.match(/\/([^\/]*)$/)[1] : null

    let obj = deployments.filter(d => { return (d.id === dpl_id) })
    if (obs.centroid.geometry) {
      return (
        <div
          key={i}
          className={classes.row2}
          onClick={e => { handleSelectDevice(e, obs.identifier) }}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-start', width: '14rem', alignContent: 'left', padding: '0rem 0.1rem' }}>{obs.label}</div>
          <div style={{ display: 'flex', justifyContent: 'flex-start', width: '13rem', alignContent: 'left', padding: '0rem 0.1rem' }}>{obs.description}</div>
          <div style={{ display: 'flex', justifyContent: 'flex-start', width: '13rem', alignContent: 'left', padding: '0rem 0.1rem' }}>{obs.centroid.geometry.coordinates[0]}, {obs.centroid.geometry.coordinates[1]}</div>
          <div style={{ display: 'flex', justifyContent: 'flex-start', width: '16rem', alignContent: 'left', padding: '0rem 0.1rem' }}>{obj[0].label}</div>
        </div>
      )
    }
    else {return null}
  })

  const handleKeyPress = async (event) => {
    if (event.key === 'Enter') {
      const keyWords = event.target.value.replace(' ', '.*')
      const res = await axios.get(`${gv.backend_url}/platforms`, { params: { regex: keyWords }, ...config })
      await setSearchResult(res.data.member)
    }
  }

  const search =
    <input
      type='text'
      className={classes.customInput}
      style={{ width: '10rem' }}
      placeholder='Search for devices...'
      onChange={e => handleSearchDeviceChange(e)}
      onKeyPress={e => handleKeyPress(e)}
    />

  const saveBtnJSX =
    <button
      style={{ width: '6rem' }}
      disabled={
        inDeployment.size &&
          // readObs.size &&
          latitude && longitude &&
          // devType &&
          devBrand &&
          devIdNum &&
          visibility &&
          address &&
          !saveClicked
          ? false
          : true
      }
      onClick={e => handleSave(e)}
    >
      {working ? `Working` : `Save`}
    </button>


  const saveBtnCrtDepJSX =
    <button
      style={{ width: '6rem' }}
      disabled={
        depId &&
          depDesc &&
          depUrl &&
          visibility &&
          !saveClicked
          ? false
          : true
      }
      onClick={e => handleSave(e)}
    >
      {working ? `Working` : `Save`}
    </button>

  const depIdJSX = <input
    className={classes.customInput}
    style={{ margin: '0rem 0rem 1rem 0rem' }}
    type='text'
    onChange={e => handleDepId(e)}
    placeholder='Project name *'
  />

  const depDescriptionJSX = <textarea
    className={classes.customInput}
    style={{ height: "6rem", maxHeight: "6rem", width: "21rem", margin: "0rem 0rem 1rem 0rem" }}
    type='textarea'
    onChange={e => handleDepDesc(e)}
    placeholder="Project's description *"
  />

  const depUrlJSX = <input
    className={classes.customInput}
    style={{ margin: '0rem 0rem 1rem 0rem', width: "21rem" }}
    type='text'
    onChange={e => handleDepUrl(e)}
    placeholder="External URL"
  />


  //---- RENDERING THE ELEMENTS ----
  return (
    <Fragment>

      {!isLoggedIn && history.replace({ pathname: `/authenticate`, provenance: 'create_and_edit_devices' })}

      {/* Create device section */}
      <div style={{ paddingTop: "3rem" }}>
        <div style={{ margin: '0rem auto' }} >
          <p className={classes.title} >{title1}</p>
          {crtEdtBtnsJSX}
        </div>
        <div style={{ display: showDepDev ? '' : 'none', margin: '2.5rem auto' }} >
          <p className={classes.title}>{title2}</p>
          {devDepBtnsJSX}
        </div>
        <div style={{ display: showCrtDev ? '' : 'none' }}>
          <div style={{ display: 'flex', margin: '1rem auto', flexFlow: 'column' }} >
            <p className={classes.title}>{crtDevTitle}</p>
            <div style={{ display: 'flex', flexFlow: 'row', margin: '0.5rem auto', justifyContent: 'center', alignItems: "center", alignContent: "middle" }}>
              {devBrandJSX}
              {deviceIdJSX}
            </div>
            <div style={{ display: 'flex', flexFlow: 'row', margin: '0.5rem auto', justifyContent: 'center', alignItems: "center", alignContent: "middle" }}>
              {visibilityJSX}
              {deviceQual}
            </div>
            <div style={{ display: 'flex', flexFlow: 'row', margin: '0.5rem auto', justifyContent: 'center', alignItems: "center", alignContent: "middle" }}>
              {selInboundJSX}
              {selOutboundJSX}
            </div>
          </div>
          <div style={{ display: 'flex', margin: '1rem auto', flexFlow: 'row', border: '0px solid green', justifyContent: 'center' }} >
            <div><p className={classes.title}>Deployments *</p>{dplsJSX}</div>
            <div><p className={classes.title}>Location *</p>{location}</div>
          </div>
          <div style={{ display: 'flex', margin: '1rem auto', flexFlow: 'row', border: '0px solid green', justifyContent: 'center' }} >
          </div>
          <div>{saveBtnJSX}</div>
        </div>
      </div>


      {/* Create deployment section */}
      <div style={{ display: showCrtDep ? '' : 'none' }}>
        <div style={{ display: 'flex', margin: '1rem auto', flexFlow: 'column' }} >
          <p className={classes.title}>{crtDepTitle}</p>
          <div style={{ display: 'flex', flexFlow: 'column', margin: '0.5rem auto', justifyContent: 'center', alignItems: "center", alignContent: "middle" }}>
            {depIdJSX}
            {depDescriptionJSX}
            {depUrlJSX}
            {visibilityJSX}
          </div>
        </div>
        <div>{saveBtnCrtDepJSX}</div>
      </div>    


      {/* Search devices section */}
      <div style={{ display: showEdtDev ? '' : 'none' }}>
        <div style={{ margin: '2.5rem auto', flexFlow: 'row', border: '0px solid green', justifyContent: 'center' }} >
          {search}
          <div style={{ display: 'block', height: '3rem' }}></div>
          {srcResults}
        </div>
      </div>


      {/* Logs */}
      <div>{log.length ?  log.map(l => <p style={{ color: l.color }}>{l.message}</p>) : ''}</div>
      <div style={{ height: '5rem' }}></div>

    </Fragment>
  )

}

export default CreateEditDevices