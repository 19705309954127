import React, { useState, useEffect, Fragment } from 'react'
import MetaTags from "react-meta-tags"
import MapGL, { Marker, NavigationControl, ScaleControl, GeolocateControl } from 'react-map-gl';
import PostcodeSearch from '../../components/PostcodeSearch/PostcodeSearch';


const Mapi = (props) => {

  const [userLocation, setUserLocation] = useState(null)
  const [pinLocation, setPinLocation] = useState(null)
  const [showPin, setShowPin] = useState(false)

  const [viewport, setViewport] = useState({
    latitude: 53.470758,
    longitude: -2.25,
    zoom: 11
  })

  // const [postcode, setPostcode] = useState('')



  useEffect(() => {
    getUserLocation()
  }, []);


  const getUserLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        setUserLocation([position.coords.latitude, position.coords.longitude])
      });

      navigator.geolocation.watchPosition(async (position) => {
        setUserLocation([position.coords.latitude, position.coords.longitude])
      });
    } else {
      console.log("User location not available");
    }
  }


  const pinUserLocation = async (e) => {
    setPinLocation(userLocation)
  };


  const handleMapClick = async (e) => {
    if (!props.freezeMap) {
      const latLng = [e.lngLat[1], e.lngLat[0]]
      let newViewport = viewport
      if (props.centerOnClick) {
        newViewport.latitude = latLng[0]
        newViewport.longitude = latLng[1]
      }
      if (props.zoomOnClick) {
        newViewport.zoom = 16
      }
      setViewport(newViewport)

      if (props.showPinOnClick === true) {
        setShowPin(true)
      }
      setPinLocation(latLng)
      // setPostcode('')
      props.onMapClick && props.onMapClick(latLng)
    }
  }


  // const centerOnUser = async (e) => {
  //   setViewport({
  //     ...viewport,
  //     latitude: userLocation[0],
  //     longitude: userLocation[1]
  //   })
  // };


  const imgs = {
    "Generic pin": require("../../imgs/sensor_icons/generic_pin.svg"),
    "Generic pin red": require("../../imgs/sensor_icons/generic_pin_red.svg"),
    check: require("../../imgs/check.svg"),
  };


  const UserLocation = userLocation ? (
    <Marker
      key='pin'
      longitude={userLocation[1]}
      latitude={userLocation[0]}
    >
      <div
        style={{
          width: "1.1rem",
          height: "1.1rem",
          cursor: "pointer",
          backgroundColor: "#48d",
          borderRadius: "50%",
          transform: "translate(-50%, -50%)",
          border: "0.15rem white solid",
          boxShadow: "0px 0px 0.5rem rgba(0,0,0,0.5)",
        }}
        onClick={pinUserLocation}
      />
    </Marker>
  ) : (
    ""
  );


  const pin = pinLocation ? (
    <Marker
      key='redpin'
      latitude={pinLocation[0]}
      longitude={pinLocation[1]}
    >
      <img
        alt=""
        src={imgs["Generic pin red"]}
        style={{
          width: "2.1rem",
          height: "3.3rem",
          cursor: "pointer",
          transform: "translate(-50%, -100%)",
          display: props.showPinOnClick && showPin ? "" : "none"
        }}
      />
    </Marker>
  ) : (
    ""
  );

  const zoomOnPostcode = e => {
    setViewport({
      latitude: e.latLng[0],
      longitude: e.latLng[1],
      zoom: 17
    })
  }


  let map = (
    <MapGL
      {...viewport}
      width="100%"
      height="100%"
      movingMethod="flyTo"
      onViewportChange={(viewport) => setViewport(viewport)}
      mapboxApiAccessToken="pk.eyJ1IjoiZXR0b3JlbXVyYWJpdG8iLCJhIjoiY2pvaGM4bHNqMGh0ejNwb2FiYjBoemplOSJ9.qUwd_2Vm07oj5L8jq2XX-w"
      mapStyle={props.mapStyle}
      // mapStyle='mapbox://styles/mapbox/bright-v9'
      onNativeClick={e => handleMapClick(e)}
      // scrollZoom={{"smooth":true}}
      // transitionDuration={300}
      // transitionInterpolator={new FlyToInterpolator()}
      attributionControl={false}
      scrollZoom={{ speed: 0.05 }}
      mapbox-logo={false}
      logoPosition='bottom-right'
    >
      {UserLocation}
      {props.datapoints}
      {pin}
      {props.baloon}

      <NavigationControl
        style={{ position: "absolute", bottom: "4rem", right: "1rem" }}
      />
      <ScaleControl style={{ position: "absolute", bottom: "0rem", right: "1rem" }} />
      <GeolocateControl
        positionOptions={{ enableHighAccuracy: true }}
        trackUserLocation={true}
        style={{ position: "absolute", bottom: "1rem", right: "1rem" }}
      />
    </MapGL>
  );



  return (
    <Fragment>
      <MetaTags>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0"
        />
        <meta
          name="display"
          content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0"
        />
      </MetaTags>

      {map}

      <PostcodeSearch
        onPostcodeFound={zoomOnPostcode}
      />
    </Fragment>
  )
}


export default Mapi  