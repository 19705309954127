import React, { useState } from "react"
import classes from "./ButtonBar.module.css"

const ButtonBar = (props) => {

  const [clickedId, setClickedId] = useState(-1)

  const n_of_buttons = props.buttons ? props.buttons.length : 0

  let buttons = []

  const clicked = async (i) => {
    setClickedId(i)
    props.selected(i)
  }

  if (n_of_buttons >= 2) {
    buttons = props.buttons.map((buttonLabel, i) => (
      <button
        className={i === clickedId ? classes.active : classes.customButton}
        style={{
          borderRadius: i === 0 ? '0.3rem 0rem 0rem 0.3rem' : i === n_of_buttons - 1 ? '0rem 0.3rem 0.3rem 0rem' : '0rem',
          borderRight: i === 0 && i < (n_of_buttons - 1) ? '1px' : '1px solid black '
        }}
        key={i}
        name={buttonLabel}
        onClick={() => clicked(i)}
        disabled={props.enabled && !Boolean(props.enabled[i])}
      >
        {buttonLabel}
      </button>
    ))
  }

  return (

    buttons
  )
}

export default ButtonBar