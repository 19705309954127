import React from "react"
import "react-datepicker/dist/react-datepicker.css"
import "react-datepicker/dist/react-datepicker-cssmodules.css"
import classes from "./queryControlsSimple.module.css"


class QueryControlsSimple extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			start_time: new Date(new Date().setDate(new Date().getDate() - 1)),
			end_time: new Date(),
			resolution: this.props.resolution, // ? this.props.resolution : "1h",
			interpolate: this.props.interpolate ? this.props.interpolate : false,
      selected: '24 h'
		};
	}

	componentDidMount() {
		this.props.onChange(this.state);
	}

	async changeDateTime(timeWindow) {
		if (timeWindow === "24 h") {
			await this.setState({
				start_time: new Date(new Date().setDate(new Date().getDate() - 1)),
				end_time: new Date(),
				resolution: "1h",
        selected: timeWindow
			});
			console.log(timeWindow);
		}

		if (timeWindow === "3 d") {
			await this.setState({
				start_time: new Date(new Date().setDate(new Date().getDate() - 3)),
				end_time: new Date(),
				resolution: "1h",
        selected: timeWindow
			});
			console.log(timeWindow);
		}

		if (timeWindow === "7 d") {
			await this.setState({
				start_time: new Date(new Date().setDate(new Date().getDate() - 7)),
				end_time: new Date(),
				resolution: "1h",
        selected: timeWindow
			});
			console.log(timeWindow);
		}

		this.props.onChange(this.state);
		this.props.refreshPlot();
	}

	async handleChangeResolution(resolution) {
		await this.setState({ resolution: resolution.target.value });
		this.props.onChange(this.state);
	}

	async toggleInterpolate(interpolate) {
		await this.setState({ interpolate: interpolate.target.checked });
		this.props.onChange(this.state);
	}

	render() {

		return (
			<div
				style={{
					display: "flex",
					justifyContent: "left",
					alignItems: "center",
					border: "0px solid red",
					width: "12rem",
				}}
			>
				<div className={classes.button}
					style={{
            color : this.state.selected === '24 h' ? 'white' : 'grey',
            backgroundColor : this.state.selected === '24 h' ? 'rgb(35,48,61)' : 'lightgrey'
					}}
					onClick={(date) => this.changeDateTime("24 h")}
				>
					24 h
				</div>

				<div className={classes.button}
					style={{
            color : this.state.selected === '3 d' ? 'white' : 'grey',
            backgroundColor : this.state.selected === '3 d' ? 'rgb(35,48,61)' : 'lightgrey'
					}}
					onClick={(date) => this.changeDateTime("3 d")}
				>
					3 d
				</div>

				<div className={classes.button}
					style={{
            color : this.state.selected === '7 d' ? 'white' : 'grey',
            backgroundColor : this.state.selected === '7 d' ? 'rgb(35,48,61)' : 'lightgrey'
					}}
					onClick={(date) => this.changeDateTime("7 d")}
				>
					7 d
				</div>

			</div>
		);
	}
}

export default QueryControlsSimple;
