import React from 'react'
import classes from './CheckBoxArea.module.css'

const CheckBoxArea = props => {

  const handleChange = async (e, i) => {
    props.onChange && props.onChange(i)
  }

  

  return (
    <div className={classes.checkBoxArea}
      style={{ height: props.height ? props.height : '', width: props.width ? props.width : '' }}>
      {props.items.map((i, index) => {
        return (
          <div key={index} style={{ display: 'flex', flexFlow: 'row nowrap', alignItems: 'center' }} >
            <input
              type='checkbox'
              style={{ margin: '0.3rem' }}
              value={i.id}
              onChange={e => handleChange(e, i)}
            />
            {i.label}
          </div>
        )
      })}
    </div>
  )
}


export default CheckBoxArea