import React, { useState, useEffect, useContext } from "react"
import axios from "axios"
import { useHistory } from "react-router-dom"

import AuthContext from "../../store/auth-context"
import LoginForm from '../../UI/Login/LoginForm'
import * as gv from "../../global_variables"
import classes from "./Auth.module.css"

import {useAnalytics} from 'use-analytics'


const Auth = props => {

	const history = useHistory() 
	const authCtx = useContext(AuthContext)
	const isLoggedIn = authCtx.isLoggedIn

	const [signup, setSignup] = useState(false)
	const [errorMessage, setErrorMessage] = useState(null)
	const [successMessage, setSuccessMessage] = useState(null)
	const [provenance, setProvenance] = useState('')
	const [pageMessage, setPageMessage] = useState(null)


	const { track } = useAnalytics()


	useEffect(() => {
		if (!!props.history.location.provenance) {
			setPageMessage(`The page you are trying to access is protected. Please login.`)
			setProvenance(props.history.location.provenance)
		}
	}, []);


	const submitHandler = async (userData) => {
		if (signup) {
			let user = { ...userData, roleId: 0 };
			try {
				await axios.post(`${gv.backend_url}/users`, user);
				await setErrorMessage(null)
				await setSuccessMessage("User created. Now you can login")
				await setSignup(false)
				track('Signup', {category: 'Signup',
				label: 'Signup',
				action: 'Signup',
				value: 1})
			} catch (e) {
				await setErrorMessage("Unable to register")
				await setSuccessMessage(null)
			}
		} else {
			let user = { email: userData.email, password: userData.password }
			try{ 
				let res = await axios.post(`${gv.backend_url}/users/login`, user)
				authCtx.login(res.data.token, res.data.user)
				track('Login', {category: 'Login',
				label: 'Login',
				action: 'Login',
				value: 1})
			} catch (e){
				await setErrorMessage(`Wrong credentials`)
			}	
		}
	};


	const toggleLogInSignUp = (e) => {
		setSignup(!signup)
		setProvenance('')
		setPageMessage(null)
		setErrorMessage(null)
	};


	return (
		<div className={classes.pageContent}>
			{isLoggedIn ? history.replace(`/${provenance}`) : null}
			<h1>{signup ? "Signup" : "Login"}</h1>

			{errorMessage && <p style={{ color: "darkRed" }}>{"-- " + errorMessage + " --"}</p>}
			{successMessage && <p style={{ color: "darkGreen" }}>{"-- " + successMessage + " --"}</p>}

			{props.history.location.provenance && <p style={{ fontSize: "large" }}>{pageMessage}</p>}

			<LoginForm
				onSubmit={submitHandler}
				signup={signup} />

			<br /><br />

			<div style={{ maxWidth: "18rem", margin: "auto" }}>
				<p>{signup ?
					`I have already an account.` : `Not registered yet?`}
				</p>
				<p
          style={{cursor: "pointer", color:"#008ABD", padding: "0.5rem"}}
          onClick={toggleLogInSignUp}> {signup ?
					`Login` : `Create a new account`}
				</p>
			</div>

			<br />
		</div>
	);

}


export default Auth
