import React, { useState, useEffect } from "react"
import axios from "axios"
import classes from "./PostcodeSearch.module.css"



const PostcodeSearch = props => {

  const [postcode, setPostcode] = useState('')

  useEffect(() => {
    const lookForPostcode = setTimeout(async () => {
      if (postcode.trim() !== '') {
        try {
          const postcode1 = postcode.replace(/\s/g, '');
          let postcodeCentre = await axios.get(`https://api.getthedata.com/postcode/${postcode.trim()}`)
          let geo = await axios.get(`https://muo-backend.cs.man.ac.uk/Postcode/${postcode1.trim()}`);
          if (postcodeCentre.data.match_type === 'unit_postcode' && postcodeCentre.data.data.latitude !== null && postcodeCentre.data.data.longitude !== null) {
            let e = {}
            e.latLng = [parseFloat(postcodeCentre.data.data.latitude), parseFloat(postcodeCentre.data.data.longitude)]
            await handlePostcodeFound(e, geo)
          }
          else {
            handlePostcodeNotFound()
          }
        }
        catch (e) {
          console.log('not found')
          console.log(e)
        }
      }
    }, 500)
    return (() => clearTimeout(lookForPostcode))
  }, [postcode])


  const handleInsertPostcode = async (event) => {
    await setPostcode(event.target.value.toUpperCase())
  }


  const handlePostcodeFound = async (e, geo) => {
    props.onPostcodeFound(e, geo.data);
    console.log(geo.data)
  }


  const handlePostcodeNotFound = async (e) => {
    props.resetPostcode();
  }

  const resetPostcode = async event => {
    await setPostcode('')
    await props.resetPostcode()
  }


  return (
    <div className={classes.postcode} style={{ position: "absolute", top: "1rem" }}>
      <img alt="" src={require('../../imgs/search1.svg')} className={classes.postcodeSearchIcon} />
      <input
        type="text"
        name="postcode"
        id="postcode"
        placeholder="Search postcode"
        value={postcode}
        className={classes.input2}
        onChange={handleInsertPostcode}
      />
      <img
        alt=""
        src={require('../../imgs/close.svg')}
        className={classes.closeIcon}
        style={{ visibility: postcode === '' ? 'hidden' : 'visible' }}
        onClick={resetPostcode}
      />
    </div>
  )

}


export default PostcodeSearch