import React from 'react'


const Separator = (props) => {

  return(
    <div style={
      {
        display: `${props.orientation === 'h' ? "inline-block" : "block"}`,
        "width": `${props.orientation === 'h' ? props.size + "px" : "1px"}`,
        "height": `${props.orientation === 'h' ? "1px" : props.size + "px"}`
      }
    }>
    </div>
  );
}

export default Separator