import React, { useState, useEffect } from "react"
import classes from "./Home.module.css"
import QueryControlsSimple from "../../UI/QueryControls/queryControlsSimple"
import axios from "axios"
import Separator from "../../UI/Separator/separator"
import TimeSeries from "../../Charts/TimeSeries"
import { useAnalytics } from 'use-analytics'


const Plot = props => {

  const [plot, setPlot] = useState(null)
  const [showPlot, setShowPlot] = useState(false)
  const [tsParams, setTsParams] = useState({})
  const [pltParams, setPltParams] = useState({
    start_time: new Date().setDate(new Date().getDate() - 1),
    end_time: new Date(),
    resolution: "1h"
  })

  const { track } = useAnalytics()

  useEffect(async () => {
    if (props.tsParams !== tsParams) {
      await createPlot()
    } else {
      setShowPlot(false)
    }
  }, [, props])

  useEffect(async () => {
    const dataTS = await getPlotData()
    await renderPlot(dataTS)
  }, [pltParams])

  //====== Class variables ======
  const base_url = `https://muo-backend.cs.man.ac.uk`;
  // const base_url = `https://muo-backend.its.manchester.ac.uk` // On premise (UoM)
  // const base_url = `http://localhost:1000`;

  const spinnerBig = (
    <img
      alt=""
      src={require("../../imgs/spinner.svg")}
      style={{ width: "7.35rem", opacity: "0.6", margin: "2.5rem auto" }}
    />
  )


  const createPlot = async () => {

    if ((props.tsParams === undefined) | (props.tsParams === {})) return;

    if (pltParams.end_time === null) {
      let end_time = new Date(props.tsParams.lastTimeStamp)
      await setPltParams({
        ...pltParams,
        end_time,
        start_time: new Date(end_time).setDate(end_time.getDate() - 1),
      })
    }

    await setTsParams(props.tsParams)
    await setShowPlot(true)
    await setPlot(null)
  }


  const refreshPlot = async (e) => {

    // await setPlot(null)
    // const dataTS = await getPlotData()
    // await renderPlot(dataTS)

  }

  const getPlotData = async () => {

    if (tsParams.ref && tsParams.quantity) {
      const res = await axios.get(`${base_url}/rawdata`, {
        params: {
          ref: tsParams.ref,
          quantity: tsParams.quantity,
          start_time: pltParams.start_time,
          end_time: pltParams.end_time,
          resolution: pltParams.resolution,
          interpolate: pltParams.interpolate,
          cumulative: tsParams.cumulative
        }
      });

      let size = 0;
      res.data.forEach(dp => size += dp.value === null ? 0 : 8);
      console.log(size, 'Bytes');

      track('plots', {
        category: 'Data Request',
        label: 'plots',
        action: 'plots',
        value: size
      });

      return res.data;
    }
  }

  const renderPlot = async (data) => {
    if (data) {
      let plot1 = {};
      plot1.title = tsParams.tsDescr
        ? tsParams.tsDescr
        : tsParams.obsDescr;
      plot1.label = [
        tsParams.obsDescr,
        tsParams.unit
          ? ` (${tsParams.unit.symbol})`
          : "",
      ].join("");
      plot1.unit = tsParams.unit;
      plot1.data = [];

      for (let p in data) {
        const key = data[p].time;
        const value = plot && plot.unit["@id"] === "count" ? data[p].value : data[p].value === null ? null : Math.round(data[p].value * 100) / 100;
        plot1.data.push({ x: key, y: value });
      }
      plot1["name"] = plot1.label;
      plot1["cumulative"] = plot1.unit ? plot1.unit["@id"] === "count" ? true : false : false;

      console.log('plot1:', plot1);

      await setPlot(plot1);
    }
  }

  const setQueryControls = async (e) => {
    await setPltParams({ ...pltParams, ...e })
  };

  const closePlot = async (e) => {
    await props.closePlot(e);
    await setShowPlot(false)
    await setTsParams({})
  }


  let plot2 = showPlot ? (
    // Container of entire plot area
    <div
      className={classes.plotBox}
      style={{
        visibility: showPlot ? "visible" : "hidden",
      }}
    >
      {window.innerWidth >= 1000 ? (
        ""
      ) : (
        <div style={{ display: "block", height: "1rem" }} />
      )}

      {/* Container of plot query controls  */}
      <div
        style={{
          display: "flex",
          flexFlow: "row wrap",
          alignItems: "center",
          justifyContent: "center",
          maxHeight: "3rem",
        }}
      >

        <QueryControlsSimple
          start_time={
            pltParams.start_time
              ? new Date(pltParams.start_time)
              : new Date()
          }
          end_time={
            pltParams.end_time
              ? new Date(pltParams.end_time)
              : new Date()
          }
          resolution={
            pltParams.resolution
              ? pltParams.resolution
              : "1h"
          }
          onChange={(e) => setQueryControls(e)}
          refreshPlot={(e) => refreshPlot(e)}
        />

        {window.innerWidth >= 1000 ? (
          <Separator orientation="h" size="20" />
        ) : (
          ""
        )}
      </div>

      <Separator orientation="v" size="15" />

      {/* Plot */}
      <div style={{ position: "relative", width: "100%", height: 'calc(100% - 50px)' }}>
        {plot ? (
          <TimeSeries
            series={plot}
            label={plot.label}
            title={plot && plot.name ? plot.name : ''}
            height={
              window.innerWidth >= 1000
                ? "100%"
                : `calc(${window.innerHeight}px - 8.5rem)`
            }
            width="100%"
          />
        ) : (
          <div
            style={{
              margin: "auto",
              textAlign: "center",
              height: 'calc(100% - 50px)',
              display: "flex",
              alignItems: "center",
            }}
          >
            {spinnerBig}
          </div>
        )}
      </div>
      <div
        style={{
          position: "absolute",
          top: "0.7rem",
          right: window.innerWidth >= 1000 ? "0.4rem" : "46%",
          transform: "ranslate(50%,50%)",
          cursor: "pointer",
          border: "0px solid green",
        }}
        className={classes.closeCross}
        onClick={(e) => closePlot(e)}
      >
        <span
          style={{
            display: "inline-block",
            verticalAlign: "middle",
            textAlign: "center",
            fontSize: "2rem",
            lineHeight: "1.5rem",
          }}
        >
          ×
        </span>
      </div>
    </div>
  ) : (
    ""
  );

  return plot2;

}

export default Plot;
