import React from "react";

const Loop3 = props =>  {


	return (
		<div
			style={{
				margin: "0rem auto",
				display: "flex",
				flexFlow: "column",
				alignItems: "center",
			}}
		>
			<h1>LOOP-3 (Local Loops & Links)</h1>
			<div
				style={{
					display: "block",
					maxWidth: "65rem",
					boxShadow: "0px 0px 20px rgba(0,0,0,0.2)",
					boxSizing: "border-box",
					backgroundColor: "rgb(255,225,129)",
					fontFamily: "'Open Sans', sans-serif",
					fontSize: "1.1rem",
					fontWeight: "400",
					lineHeight: "1.7rem",
					padding: "1rem 3rem",
					textAlign: "justify",
				}}
			>
				<p>
					<b>LOOP-3</b> is a small pilot / demo, which follows on the{" "}
					<a href="https://www.Looperproject.eu" target="_blank" rel="noopener noreferrer">
						Looper project
					</a>
					. The main aim is to develop new methods and tools for policy
					coordination / citizen engagement at the local level. We work
					closely with the ‘Bringing Services Together’ (BST) program of
					Manchester City Council, in three inner city neighbourhoods: Hulme, Moss
					Side and Rusholme.
				</p>
			</div>

			<div
				style={{
					display: "block",
					maxWidth: "59rem",
					boxSizing: "border-box",
					fontFamily: "'Open Sans', sans-serif",
					fontSize: "1rem",
					fontWeight: "400",
					lineHeight: "1.7rem",
					textAlign: "justify",
					margin: "1rem 0rem 4rem 0rem",
				}}
			>
				<p>
					The <b>Manchester-i City-Survey</b> is an important part of this. We
					aim to develop & test an online mapping platform, fitted to the
					needs of the BST partners. There are three development stages planned:
				</p>

				<ol style={{paddingLeft: "4rem", margin: "2rem 0rem", lineHeight: "1rem"}}>
					<li><b>Prototyping</b>: initial user trials;</li>
					<li><b>Tech development</b>: with selected BST participants</li>
					<li><b>‘Beta test’ roll-out</b>: with full BST organizations.</li>
				</ol>

				<p>
					Following that, a platform would be launched for general public
					access.
				</p>

				<p>
					The <b>Manchester-i City-Survey</b> aims to test some basic
					questions at this early stage. These can inform the BST ‘Sense of
					Place’ workstream, the Sharing Resources & Data Insight programs,
					and also the Safer Streets program in Moss Side.
				</p>

				<p>
					We are interested in your experience & ideas, both on problems &
					ways to improve, for each of three themes: Health and Wellbeing:
					Community Safety: Local Environment. The full list of questions so
					far includes:
				</p>

				<div
					style={{
						border: "2px solid rgb(240,175,150)",
						backgroundColor: "rgba(240,175,150, 0.3)",
						padding: "0.5rem 2rem 1rem 2rem",
						margin: "1.5rem 0rem"
					}}
				>
					<h3>Health and wellbeing</h3>
					<p>
						<b>Q1</b> – What is the most urgent <b>problem</b> in this
						location? (crime, unti-social behaviour, traffic, public
						transport, public health, litter, other...)
					</p>
					<p>
						<b>Q2</b> – What would be the best way to <b>improve</b> this
						place? (public facilities, green areas, meeting points, schools,
						playgrounds, other...)padding-left
					</p>
				</div>
				{/* <div style={{ height: "1rem" }} /> */}
				<div
					style={{
						border: "2px solid rgb(240,200,150)",
						backgroundColor: "rgba(240,200,150, 0.3)",
						padding: "0.5rem 2rem 1rem 2rem",
						margin: "1.5rem 0rem"
					}}
				>
					<h3>Community safety</h3>
					<p>
						<b>Q1</b> – What is the most urgent safety <b>problem</b> in this
						location? (traffic, crime, social disorder, too crowded, too
						isolating, other...)
					</p>
					<p>
						<b>Q2</b> – What would be the best ways to <b>improve</b>{" "}
						community safety in this location? (road safety,security defence,
						policing, CCTV, more active community, other...)
					</p>
				</div>
				{/* <div style={{ height: "1rem" }} /> */}
				<div
					style={{
						border: "2px solid rgb(220,220,160)",
						backgroundColor: "rgba(220,220,160, 0.3)",
						padding: "0.5rem 2rem 1rem 2rem",
						margin: "1.5rem 0rem"
					}}
				>
					<h3>Local environment</h3>
					<p>
						<b>Q1</b> – What is the most urgent environmental <b>issue</b> in
						this location? (traffic, pollution, litter, bad smell, noise, lack
						of green areas, other...)
					</p>
					<p>
						<b>Q2</b> – What would be the best ways to <b>improve</b> the
						environment in this location? (reducing traffic, cycle lanes, tree
						planting, community clean-up, more law enforcement, other...)
					</p>
				</div>

				<p>
					BST partners are invited to make a number of inputs on a trial
					basis, to test the system and its applications. It will be helpful
					if you can target 1-2 particular areas:
				</p>

				<ul style={{paddingLeft: "4rem", margin: "2rem 0rem", lineHeight: "1rem"}}>
					<li>
						<b>Local focus</b>: areas where there are obvious problems and/or high
						community engagement
					</li>
					<li>
						<b>Policy focus</b>: areas where major policies / programs are active or
						planned.
					</li>
				</ul>

				<p>In each of these we would aim for a range of inputs:</p>

				<ul style={{paddingLeft: "4rem", margin: "2rem 0rem", lineHeight: "1rem"}}>
					<li>
						different locations (e.g. main road, side street, shopping area,
						public space)
					</li>
					<li>different times of day and week.</li>
					<li>different weather conditions.</li>
				</ul>

				<p>
					BST partners are also invited for a preliminary evaluation of this
					prototype, and to record which type of organization do they
					represent.
				</p>

				<p>
					With a wide range of inputs on the Manchester-I platform, we can
					begin to identify the priority issues on a local basis, and also the
					ideas for improvement. In some areas we also link the results with
					the technical monitoring data from other Manchester-I projects.
				</p>

				<p>
					To start the survey please visit{" "}
					<a href="https://manchester-i.com/cs">
						https://manchester-i.com/cs
					</a>
				</p>

				{/* <p>Many thanks for your contributions and looking forward to results</p>

				<p>Manchester-I / Loop-3 team.</p> */}
			</div>
		</div>
	)
	
}

export default Loop3;
