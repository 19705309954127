import React, { useContext, useEffect } from "react";
import * as gv from '../../global_variables';
import axios from "axios";
import AuthContext from "../../store/auth-context";

const Logout = props => {

	const authCtx = useContext(AuthContext)
	let config = {}

	useEffect(() => {

		config = {
			headers: {
				Authorization: "Bearer " + authCtx.token,
			},
		}

		logout()

	}, []);

	const logout = async () => {
		authCtx.logout()
		try {
			await axios.post(`${gv.backend_url}/users/logoutAll`, {}, config);
		} catch (e) {
			console.log(e);
		}
	}



	return (
		<h1
			style={{
				margin: "0",
				padding: "0",
				height: "15rem",
				opacity: "50%",
				display: "flex",
				alignItems: "center",
				justifyItems: "center",
				width: "100%",
				justifyContent: "center"
			}}
		>
			<div style={{ textAlign: "center"}}>
				You've logged out
			</div>
		</h1>
	)
}


export default Logout