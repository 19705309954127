import React, {Fragment} from "react";
import NavBar from '../NavBar/NavBar';
import MainContent from '../MainContent/MainContent';

const NavBarLayout = () => {
	return (
    <Fragment>
		  <MainContent />
      <NavBar />
    </Fragment>
	);
}

export default NavBarLayout;