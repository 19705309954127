import React, { useState, useEffect } from "react";
import axios from "axios";
import Separator from "../../UI/Separator/separator";
import classes from "./Home.module.css";
import Plot from "./plot";
import MappleToolTip from "reactjs-mappletooltip";

const InfoBox = props => {

  const [ts, setTs] = useState([])
  const [platform, setPlatform] = useState(null)
  const [showInfoBox, setShowInfoBox] = useState(true)
  const [showPlot, setShowPlot] = useState(false)
  // const [params, setParams] = useState({})
  const [selected, setSelected] = useState(false)
  // const [plot, setPlot] = useState(null)
  const [tsParams, setTsParams] = useState({})

  useEffect(async () => {
    // console.log(props)
    if (props.platform !== platform) {
      if (props.platform === null || props.platform === undefined) return
      await populateInfoBox();
    }
    // console.log(props)
    await setShowInfoBox(props.showInfoBox)
    await setShowPlot(props.showPlot)
  }, [, props])

  // useEffect(() => {console.log(props)}, [showInfoBox])

  //====== GLOBAL VARIABLES ======
  const base_url = `https://muo-backend.cs.man.ac.uk`;
  // const base_url = `https://muo-backend.its.manchester.ac.uk` // On premise (UoM)
  // const base_url = `http://localhost:1000`;

  const months = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };


  // showInfoBox = this.props.showInfoBox

  const spinner = (
    <img
      alt=""
      src={require("../../imgs/spinner.svg")}
      style={{ width: "3.35rem", margin: "2.5rem auto" }}
    />
  );

  const plotIcon = require("../../imgs/plot2.svg")



  const populateInfoBox = async () => {
    await setTs([])
    await setPlatform(props.platform)

    const sns_ids = [];
    props.platform.hosts.forEach((h) =>
      sns_ids.push(h.match(/([^\/]+)$/g)[0])
    )

    // console.log(sns_ids);

    const res = await axios.post(`${base_url}/timeseries`, {
      madeBySensor: sns_ids,
    });
    // console.log(res.data)
    const ts1 = res.data.member;

    for (let t in ts1) {
      const res = await axios.get(`${base_url}/lastvalue`, {
        params: {
          ref: ts1[t].identifier,
          quantity: ts1[t].observedProperty["@id"],
        },
      })

      let lastVal = "NA";
      let lastUpd = "";

      if (res.data.length) {
        const lv = res.data[0];
        let date = new Date(lv.time);
        ts1[t].lastTimeStamp = lv.time;
        ts1[t].fromTimeStamp = new Date(
          new Date(lv.time).setDate(date.getDate() - 1)
        ).toISOString();
        let today = new Date();

        today.setHours(date.getHours());
        today.setMinutes(date.getMinutes());
        today.setSeconds(date.getSeconds());

        const time = `${lv.time.substring(11, 13)}:${lv.time.substring(
          14,
          16
        )}`;
        if (today - date < 86400000) {
          lastUpd = `Today at ${time}`;
        } else if (today - date < 2 * 86400000) {
          lastUpd = `Yesterday at ${time}`;
        } else if (today - date < 3 * 86400000) {
          lastUpd = <span style={{ color: "#d36500" }}>2 days ago</span>;
        } else {
          lastUpd = date ? (
            <MappleToolTip
              float={false}
              direction={"right"}
              style={{ display: "inline-block" }}
              padding="0.2rem 0.45rem"
              backgroundColor="rgba(0,0,0,0.7)"
            >
              <span style={{ color: "red", cursor: "default" }}>
                {date.getDate()} {months[date.getMonth() + 1]}{" "}
                {date.getFullYear()}
              </span>
              <div style={{ maxWidth: "9rem", fontFamily: "Roboto, sans-serif", fontSize: "1rem" }}>
                This data is at least 3 days old. The device might need
                maintenance or it may be no longer in use.
              </div>
            </MappleToolTip>
          ) : (
            ""
          )
        }

        lastVal = `${lv.last.toFixed(2)} ${ts1[t].unit.symbol}`
      }
      ts1[t].lastVal = lastVal
      ts1[t].lastUpd = lastUpd
    }
    setTs(ts1)
  }

  // const setParams2 = async (e, params) => {
  //   await setParams(params);
  // }

  // Set TimeSeries parameters
  const setTSParams = async (
    e,
    ref,
    quantity,
    obsDescr,
    tsDescr,
    unit,
    lastVal,
    lastUpd,
    lastTimeStamp
  ) => {
    const tsParams1 = {
      ref,
      quantity,
      cumulative: unit["@id"] === "count" ? true : false,
      obsDescr,
      tsDescr,
      unit,
      lastVal,
      lastUpd,
      lastTimeStamp,
    };

    await setShowPlot(true)
    await setSelected(ref)
    // await setPlot(null)
    await setTsParams(tsParams1)
  }

  const closePlot = async (e) => {
    await setShowPlot(false)
    await setSelected(null)
  }


  const closeInfoBox = async (e) => {
    await setShowInfoBox(false)
    await setShowPlot(false)
    await props.closeInfoBox()
  }




  const infobox = (
    <div
      className={classes.infoBox}
      style={{ boxShadow: showPlot ? "none" : "0rem 0rem 0.5rem rgba(0,0,0,0.5)", borderRight: showPlot ? "1px dotted grey": "none" }}
      key='12345'
    >


      <div
        style={{
          position: "absolute",
          top: "0.8rem",
          right: "0.6rem",
          cursor: "pointer"
        }}
        className={classes.closeCross}
        onClick={(e) => closeInfoBox(e)}
      >
        <span
          style={{
            display: "inline-block",
            verticalAlign: "middle",
            textAlign: "center",
            fontSize: "2rem",
            lineHeight: "1.5rem",
          }}
        >
          ×
        </span>
      </div>


      {ts.length ? (
        <div style={{ color: "rgb(80,80,80)" }}>
          <p className={classes.infoBoxTitle}>Last readings:</p>
          {ts.map((ts) => (
            <div
              key={ts.identifier}
              style={{
                fontSize: "0.9rem",
                display: "flex",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <div style={{ whiteSpace: "pre-wrap", width: "100%" }}>
                <span style={{ fontWeight: "600" }}>
                  {ts.observedProperty.label}
                  {ts.description ? `\n(${ts.description})` : ""}
                  {"\n"}
                </span>
                <span style={{ fontWeight: "500" }}>{ts.lastVal}</span> <br />
                <span style={{ fontSize: "0.9rem", color: "#333" }}>
                  {ts.lastUpd}
                </span>
              </div>{" "}
              <Separator orientation="h" size="5"></Separator>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "-0.8rem",
                }}
              >
                <img
                  alt="Show Plot"
                  src={plotIcon}
                  className={classes.plotBtn}
                  style={{
                    opacity:
                      selected === ts.identifier ? "1" : "0.5",
                    border:
                      selected === ts.identifier
                        ? "1px solid #ccc"
                        : "1px solid #eee",
                  }}
                  onClick={(e) =>
                    setTSParams(
                      e,
                      ts.identifier,
                      ts.observedProperty["@id"],
                      ts.observedProperty.label,
                      ts.description,
                      ts.unit,
                      ts.lastVal,
                      ts.lastUpd,
                      ts.lastTimeStamp
                    )
                  }
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          Fetching data...
          <br />
          {spinner}
        </div>
      )}
    </div>
  );

  return (
    <div
      className={classes.infoContainer}
      style={{
        display: showInfoBox ? "flex" : "none",
        boxShadow: showPlot ? "0rem 0rem 0.5rem rgba(0,0,0,0.5)" : "none"
      }}
    >
      {infobox}
      <Plot
        tsParams={tsParams}
        closePlot={(e) => closePlot(e)}
        showPlot={showPlot}
      />
    </div>
  );

}

export default InfoBox;
