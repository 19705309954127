import React, { useState } from 'react';

const AuthContext = React.createContext({
  token: '',
  isLoggedIn: false,
  user: null,
  login: (token, user) => { },
  logout: () => { }
})


export const AuthContextProvider = props => {
  const initialToken = localStorage.getItem('token')
  const initialUser = localStorage.getItem('user')
  const [token, setToken] = useState(initialToken)
  const [user, setUser] = useState(initialUser)

  const userIsLoggedIn = !!token

  const loginHandler = (token, user1) => {
    setToken(token)
    localStorage.setItem('token', token)

    setUser(user1)
    localStorage.setItem('user', user1.roleId)
  }
  const logoutHandler = () => {
    setToken(null)
    localStorage.removeItem('token')

    setUser(null)
    localStorage.removeItem('user')
  }

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    user: user,
    login: loginHandler,
    logout: logoutHandler
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthContext