import React, { useState, useEffect, useContext } from "react"
import axios from "axios"
import classes from "./Deployments.module.css"
import ReactMapGL, { Marker } from 'react-map-gl'
import Separator from "../../UI/Separator/separator"
import QueryControlsStateless from "../../UI/QueryControls/queryControlsStateless"
import { Button } from "glamorous"
import { ExportToCsv } from "export-to-csv"
import TimeSeries from "../../Charts/TimeSeries"
import Card from "../../UI/Card/Card"

import * as gv from "../../global_variables"

import { Disclaimers } from "./Disclaimers"
import AuthContext from "../../store/auth-context"
import { useAnalytics } from 'use-analytics'
import Tile from "../../UI/Tile/tile"

const Deployments = props => {

	const authCtx = useContext(AuthContext)
	const isLoggedIn = authCtx.isLoggedIn

	const { track } = useAnalytics()

	// const history = useHistory()


	const [map, setMap] = useState({ latitude: 53.470758, longitude: -2.25, zoom: 12 })
	const [deployments, setDeployments] = useState([])
	const [deployment, setDeployment] = useState(null)
	const [platforms, setPlatforms] = useState(null)
	const [platform, setPlatform] = useState({})
	const [platformHover, setPlatformHover] = useState(null)
	const [sensors, setSensors] = useState(null)
	const [sensor, setSensor] = useState(null)
	// const [showSensors, setShowSensors] = useState(true)
	const [showPlots, setShowPlots] = useState(false)
	const [showAll, setShowAll] = useState(false)
	const [checkAll, setCheckAll] = useState(false)
	const [chkSgl, setChkSgl] = useState([])
	const [data, setData] = useState([])
	const [showBaloon, setShowBaloon] = useState(false)
	const [loadingPlatforms, setLoadingPlatforms] = useState(false)
	const [querySettings, setQuerySettings] = useState({})
	const [loadingData, setLoadingData] = useState(false)
	const [loadingSensors, setLoadingSensors] = useState(false)


	//========== GLOBAL VARIABLES ==========

	const months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
		'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
	]

	const spinner = (
		<img
      alt=""
			src={require("../../imgs/spinner.svg")}
			style={{ width: "4.5rem", margin: "2rem auto" }}
		/>
	)

	const back = (
		<img
      alt=""
			src={require("../../imgs/back.svg")}
			style={{ width: "1.6rem" }}
		/>
	)

	const imgs = {
		"Traffic camera": require("../../imgs/sensor_icons/traffic_camera3.svg"),
		"Automatic Traffic Counter": require("../../imgs/sensor_icons/traffic_camera3.svg"),
		"Automatic Cycle Counter": require("../../imgs/sensor_icons/traffic_camera3.svg"),
		"Noise monitor": require("../../imgs/sensor_icons/noise_monitor3.svg"),
		"Air-quality station": require("../../imgs/sensor_icons/air_quality_station3.svg"),
		"Air-quality monitor": require("../../imgs/sensor_icons/air_quality_station3.svg"),
		"Air-quality device": require("../../imgs/sensor_icons/air_quality_station3.svg"),
		"Meteo station": require("../../imgs/sensor_icons/weather_station.svg"),
		"Meteo monitor": require("../../imgs/sensor_icons/weather_station.svg"),
		"Meteo device": require("../../imgs/sensor_icons/weather_station.svg"),
		"Generic pin": require("../../imgs/sensor_icons/generic_pin.svg"),
		"Generic pin red": require("../../imgs/sensor_icons/generic_pin_red.svg"),
		"River-level station": require("../../imgs/sensor_icons/water_level_station.svg"),
	};

	const config = {
		headers: {
			Authorization: "Bearer " + authCtx.token,
		}
	}


	//============== QUERIES ===============

	// GET all deployments
	const getDeployments = async () => {
		const response = await axios.get(
			`${gv.backend_url}/mongo/deployments`,
			config
		)
		setDeployments(response.data)
		setData(null)
		setPlatforms(null)
		setPlatform(null)
	}

	useEffect(() => {
		getDeployments();
	}, []);


	useEffect(async () => {
		await getPlatformsFromDeployment()
	}, [deployment])


	// GET selected deployment
	const getSelectedDeployment = async deployment_id => {
		const res = await axios.get(
			`${gv.backend_url}/mongo/deployments/${deployment_id}`,
			config
		)
		return res.data;
	}

	// GET all the platforms of the selected deployment
	const fetchPlatforms = async deployment_id => {
		setMap({ zoom: 12, latitude: 53.480758, longitude: -2.27 })
		setShowBaloon(false)
		setCheckAll(false)
		setChkSgl([])
		setDeployment(null)
		setPlatforms(null)
		setPlatformHover(null)
		setSensors(null)
		setData(null)
		setLoadingPlatforms(true)
		setShowAll(false)

		const dpl = await getSelectedDeployment(deployment_id)
		setDeployment(dpl)
		// let plt_ids = dpl.deployedOnPlatform
		// const plts = await getPlatformsFromDeployment(plt_ids)
		setPlatforms(plts)
		setLoadingPlatforms(false)
	}

	// GET all the platforms listed in 'plat_ids'
	const getPlatformsFromDeployment = async () => {

		const plts_ids = deployment ? deployment.deployedOnPlatform : null

		// Get platforms from ids
		let res = await axios.post(`${gv.backend_url}/mongo/platforms`, { ids: plts_ids }, config)
		const plts = res.data

		// Get locations from platform ids
		res = await axios.post(`${gv.backend_url}/mongo/locations`, { ids: plts_ids }, config)
		const allLocs = res.data

		let pltsPlus = []

		plts_ids && plts_ids.forEach(plt_id => {
			let plt = plts.filter(p => p.id === plt_id)
			let pltLocs = allLocs.filter(locs => {
				return locs.platform === plt_id
			});

			try {
				pltLocs[0].locations && pltLocs[0].locations.forEach(pl => {
					if (pl.inDeployment === deployment.id) {
						pltsPlus.push({ ...plt[0], ...pl })
					}
				})
			}
			catch (e) {
				console.log(e)
			}
		})

		let lat = 0, lon = 0, count = 0
		pltsPlus.forEach(p => {
			if (p.coordinates) {
				lat += p.coordinates[1]
				lon += p.coordinates[0]
				count += 1
			}
		})
		lat = lat / count
		lon = lon / count

		setMap({ ...map, latitude: lat, longitude: lon })
		setPlatforms(pltsPlus)
		setLoadingPlatforms(false)

		// return pltsPlus;
	}


	// GET list of sensors belonging to selected platform
	const getSensorsFromPlatform = async plt => {
		setSensors(null)
		setCheckAll(false)
		setChkSgl([])
		setLoadingSensors(true)
		setData(null)

		setPlatform(plt);
		const sensors_ids = plt.hosts;
		let response = await axios.post(`${gv.backend_url}/timeseriesmongo`, {
			madeBySensor: sensors_ids
		})

		const snrs = response.data
		setSensors(snrs)
		setLoadingSensors(false)
	}

	// GET data for timeseries
	const fetchData = async (action) => {
		let dt = [];
		await setLoadingData(true)
		await setData(dt)

		// For each time series selected...
		for (let i in chkSgl) {
			const snr = [...sensors].filter(s => s.id === chkSgl[i])[0]

			// ... query for raw data with the selected parameters settings
			try {
				const res = await axios.get(`${gv.backend_url}/rawdata`, {
					params: {
						ref: chkSgl[i],
						start_time: querySettings.start_time.toISOString(),
						end_time: querySettings.end_time.toISOString(),
						resolution: querySettings.resolution,
						interpolate: querySettings.interpolate,
						quantity: snr.observedProperty["@id"],
						cumulative: snr.cumulative,
					},
				})
				let ts = {}
				ts.name = `${snr.observedProperty.label} ${snr.comment ? " - " + snr.comment : ""
					} ${snr.unit.symbol ? "(" + snr.unit.symbol + ")" : ""}`
				ts.cumulative = snr.cumulative
				ts.data = []
				for (let p in res.data) {
					const key = res.data[p].time
					const value = res.data[p].value === null ? res.data[p].value : Math.round(res.data[p].value * 100) / 100
					ts.data.push({ x: key, y: value })
				}
				dt.push(ts)
			} catch (e) {
				console.log(e.message)
			}
		}
		await setData(dt)
		await setLoadingData(false)
		if (action === "download") await createCSV(dt)
		if (action === "plots") await setShowPlots(true)

		let size = 0

		dt.forEach(ts => {
			const ss = ts.data.filter(v => v !== null)
			size += ss.length * 8
		})

		track(action, {
			category: 'Data Request',
			label: action,
			action: action,
			value: size
		})

	}

	// Generate CSV file for download
	const createCSV = async dt => {

		if (dt.length > 0) {
			const timestamps = dt[0].data.map(dp => dp.x)
			let values = []
			for (let ts in dt) {
				const vals = dt[ts].data.map(dp => dp.y)
				values.push(vals);
			}
			let rows = [];
			for (let t in timestamps) {
				let row = {
					timestamp: `${timestamps[t].substring(8, 10)}-${timestamps[
						t
					].substring(5, 7)}-${timestamps[t].substring(0, 4)} ${timestamps[
						t
					].substring(11, 13)}:${timestamps[t].substring(14, 16)}:${timestamps[
						t
					].substring(17, 19)} `,
				};
				for (let v in values) {
					row[dt[v].name] = values[v][t]
				}
				rows.push(row)
			}

			const disclaimer = platformHover.disclaimer
				? `"DISCLAIMER: ${Disclaimers[platformHover.disclaimer]
				}"\n\n`
				: ""
			const dpl = `"Deployment: ${deployment.label} - ${deployment.description}"\n`
			const plt = `"Platform: ${platformHover.label} (${platformHover.description})"`

			const options = {
				fieldSeparator: ",",
				quoteStrings: '"',
				decimalSeparator: ".",
				showLabels: true,
				showTitle: true,
				title: `${disclaimer}${dpl}${plt} `,
				useTextFile: false,
				useBom: true,
				useKeysAsHeaders: true,
			}

			const csvExporter = new ExportToCsv(options);
			csvExporter.generateCsv(rows);

		}
	}

	//========= OTHER FUNCTIONS ============

	const swtc = async (e) => {
		if (checkAll) setChkSgl([])
		else {
			let cs = []
			for (let i in sensors) {
				cs.push(sensors[i].id)
			}
			await setChkSgl(cs)
		}
		await setCheckAll(!checkAll)
	};

	const swtcref = async (ref) => {
		let cs = [...chkSgl]
		if (cs.includes(ref)) {
			const index = cs.indexOf(ref)
			if (index > -1) {
				cs.splice(index, 1)
				await setCheckAll(false)
			}
			await setChkSgl(cs)
		} else {
			cs.push(ref)
			await setChkSgl(cs)
		}
	}

	const deselectPlatform = async () => {
		setShowBaloon(false)
		setSensors(null)
		// setShowSensors(false)
		setPlatformHover(null)
		setPlatform(null)
		setData(null)
	}


	const focusOnPlatform = async (e, pltHover) => {
		await deselectPlatform()

		if (pltHover.static) {
			setMap({ ...map, latitude: pltHover.coordinates[1], longitude: pltHover.coordinates[0], zoom: 20 })
			setShowBaloon(true)
			setPlatformHover(pltHover)
		}

		getSensorsFromPlatform(pltHover);
	}


	const backToProjects = async e => {
		await setData(null)
		await setPlatformHover(null)
		await setPlatforms(null)
		await setPlatform(null)
		await setSensors(null)
		await setDeployment(null)
	}

	const setQueryControls = async (e) => {
		setQuerySettings(e)
	}

	const showMoreLess = async (e) => {
		setShowAll(!showAll)
	}

	const download = () => {
		// fake server request, getting the file url as response
		setTimeout(() => {
			const response = {
				file: deployment.id === 'gmt' 
        ? `${gv.backend_url}/download/EART20192_Geospatial_Collated_Student.xlsx` 
          : deployment.id === 'aviator' 
          ? `${gv.backend_url}/download/Aviator.csv` 
            : '',
			};
			// server sent the url to the file!
			// now, let's download:
			window.location.href = response.file;
			// you could also do:
			// window.open(response.file);
		}, 100);
	}

	//======================================

	let deploymentsList = [];

	let dpls = deployments
  let img;
	for (let d in dpls) {
    try{
      img = require(`../../imgs/project_images/${dpls[d].id}.png`);
    } catch(e) {
      img = require(`../../imgs/project_images/generic_project.png`);
    }
		const startDate = new Date(dpls[d].startDate);
		const endDate = dpls[d].endDate
			? new Date(dpls[d].endDate)
			: null;
		deploymentsList.push(
      <Tile 
        title={dpls[d].label}
        description={dpls[d].description}
        image={img}
        startDate = {("0" + startDate.getDate()).slice(-2) +
        " " + months[startDate.getMonth()] +
        " " +
        startDate.getFullYear()}
        endDate={endDate
          ? ("0" + endDate.getDate()).slice(-2) +
          " " + months[endDate.getMonth()] +
          " " +
          endDate.getFullYear()
          : "present"}
        onClick={
						dpls[d].deployedOnPlatform.length > 0
							? (e) => fetchPlatforms(dpls[d].id)
							: null
					}
          projectPage = {dpls[d].externalLink ? (
						<a href={dpls[d].externalLink} target="blank">
							Project page
						</a>
					) : (
						""
					)}
      />
	
		);
	}

	let deploymentsBox = <div className={classes.box1} 
  style={{ display: deployment && platforms ? "none" : "flex", alignItems: "center", justifyContent: "center", flexFlow: "row wrap" }}>{deploymentsList}</div>;

	let platformsList = [
		<div key="header" className={classes.header}>
			<div
				className={classes.cell}
				style={{
					width: "1.5rem",
					margin: "-0.1rem 0rem -0.1rem 0rem",
					textAlign: "left",
				}}
			>
				<img
          alt=""
					src={imgs["Generic pin"]}
					style={{
						width: "1rem",
						margin: "-0.1rem 0rem",
						verticalAlign: "middle",
						display: "block",
					}}
				/>
			</div>
			<div className={classes.cell} style={{ width: "15rem" }}>
				Name
			</div>
			<div className={classes.cell} style={{ width: "10rem" }}>
				Start Date
			</div>
			<div className={classes.cell} style={{ width: "10rem" }}>
				End Date
			</div>
			<div className={classes.cell} style={{ width: "23rem" }}>
				Location
			</div>
		</div>
	];

	let plts = platforms
	for (let p in plts) {
		const startDate = plts[p].startDate;
		const endDate = plts[p].endDate;
		const address = plts[p].address;

		const plt_id = plts[p]["id"];
		platformsList.push(
			<div
				key={plt_id + startDate + endDate}
				className={classes.row}
				style={{
					color: platform
						? plt_id === platform["id"] && platform["startDate"] === startDate
							? "#000"
							: "#777"
						: "#777",
					backgroundColor: platform
						? plt_id === platform["id"] && platform["startDate"] === startDate
							? "#f0f0f0"
							: ""
						: "",
					display: p >= 4 ? (showAll ? "" : "none") : "",
					opacity:
						platforms &&
							(p >= 3) & (platforms.length > 4) & !showAll
							? 0.5
							: 1,
				}}
			>
				<div
					className={classes.cell}
					style={{
						width: "1.5rem",
						margin: "-0.1rem 0rem -0.1rem 0rem",
						textAlign: "left",
					}}
				>
					<img
            alt=""
						src={
							imgs[plts[p].description]
								? imgs[plts[p].description]
								: imgs["Generic pin red"]
						}
						style={{
							width: "1rem",
							margin: "-0.0rem 0rem",
							verticalAlign: "top",
							display: "block",
						}}
					/>
				</div>

				<div
					className={classes.cell2}
					style={{ width: "15rem" }}
					onClick={(e) =>
						focusOnPlatform(e, plts[p])
					}
				>
					{plts[p].label ? plts[p].label : "- - -"}
				</div>
				<div className={classes.cell} style={{ width: "10rem" }}>
					{`${new Date(plts[p].startDate).getDate()} 
						${months[parseInt(new Date(plts[p].startDate).getMonth())]} 
						${new Date(plts[p].startDate).getFullYear()}`}
				</div>
				<div className={classes.cell} style={{ width: "10rem" }}>
					{plts[p].endDate
						? `${new Date(plts[p].endDate).getDate()} 
								${months[parseInt(new Date(plts[p].endDate).getMonth())]} 
								${new Date(plts[p].endDate).getFullYear()}`
						: "in use"}
				</div>
				<div className={classes.cell} style={{ width: "23rem" }}>
					{plts[p].static ? (
						<div>{address}</div>
					) : (
						"This is a wearable / mobile device"
					)}
				</div>
			</div>
		);
	}


	platformsList.push(
		platforms && platforms.length > 4 ? (
			showAll ? (
				<div className={classes.showML} onClick={(e) => showMoreLess(e)}>
					Show less &#x25b3;
				</div>
			) : (
				<div className={classes.showML} onClick={(e) => showMoreLess(e)}>
					Show more &#x25bd;
				</div>
			)
		) : (
			""
		)
	);

	const markers = platforms
		? platforms.map((plt) => {
			const coordinates = plt.coordinates

			return (
				<Marker
					key={plt.id + plt.startDate}
					longitude={coordinates[0]}
					latitude={coordinates[1]}
					captureClick={false}
				>
					<img
            alt=""
						src={
							imgs[plt.description]
								? imgs[plt.description]
								: imgs["Generic pin red"]
						}
						className={classes.marker}
						style={{
							width: "1.4rem",
							height: "2.2rem",
							cursor: "pointer",
							opacity: platform
								? platform.id === plt.id
									? 1
									: 0.4
								: 1,
						}}
						onClick={(e) =>
							focusOnPlatform(e, plt)
						}
					/>
				</Marker>
			);
		})
		: [];

	let platformsBox = (
		<div className={classes.box}>
			{platformsList}

			<div
				style={{
					width: "65rem",
					height: "35rem",
					border: "0.3rem solid #f7f7f7",
					boxSizing: "border-box",
					margin: "0.5rem -0.5rem -0.8rem -0.5rem",
				}}
			>
				<ReactMapGL
					{...map}
					width="100%"
					height="100%"
					onViewportChange={viewport => {
						setMap(viewport)
					}}
					mapboxApiAccessToken="pk.eyJ1IjoiZXR0b3JlbXVyYWJpdG8iLCJhIjoiY2pvaGM4bHNqMGh0ejNwb2FiYjBoemplOSJ9.qUwd_2Vm07oj5L8jq2XX-w"
					mapStyle="mapbox://styles/mapbox/bright-v9"
					onNativeClick={e => deselectPlatform()}
					scrollZoom={{ "smooth": true }}
					// transitionDuration={0}
					// transitionInterpolator={new FlyToInterpolator()}
					attributionControl={false}
					mapbox-logo={false}
					logoPosition='bottom-right'
					pitch={0}
				>
					{markers}
					<Marker
						longitude={platformHover ? platformHover.coordinates[0] : map.longitude}
						latitude={platformHover ? platformHover.coordinates[1] : map.latitude}
						captureClick={false}
					>
						<div className={classes.baloon} style={{ display: showBaloon ? "" : "none" }}>
							{platformHover ? (
								<div
									style={{
										fontFamily: "'Open Sans', sans-serif",
										margin: "0rem",
									}}
								>
									<div style={{ fontWeight: "600" }}>
										{platformHover.label}
									</div>
									<div>{platformHover.description}</div>
									<Separator orientation="v" size="10"></Separator>
									<div>
										{platformHover.startDate
											? platformHover.startDate.substring(8, 10) +
											"-" +
											platformHover.startDate.substring(5, 7) +
											"-" +
											platformHover.startDate.substring(0, 4) +
											" to "
											: "??? to "}
										{platformHover.endDate
											? platformHover.endDate.substring(8, 10) +
											"-" +
											platformHover.endDate.substring(5, 7) +
											"-" +
											platformHover.endDate.substring(0, 4)
											: "present"}
									</div>
								</div>
							) : (
								""
							)}
						</div>
					</Marker>
				</ReactMapGL>

			</div>
		</div>
	)

	let sensorsList = [
		<div key="header" className={classes.header}>
			<div className={classes.cell} style={{ width: "10rem" }}>
				Observable
			</div>
			<div className={classes.cell} style={{ width: "4rem" }}>
				Unit
			</div>
			<div className={classes.cell} style={{ width: "6rem" }}>
				Interval
			</div>
			<div className={classes.cell} style={{ width: "6rem" }}>
				Procedure
			</div>
			<div className={classes.cell} style={{ width: "16rem" }}>
				Comment
			</div>
			<div className={classes.cell} style={{ width: "8rem" }}>
				Last Updated
			</div>
			<div className={classes.checkbox} style={{ width: "1rem" }}>
				<input
					type="checkbox"
					style={{ display: "inline" }}
					checked={checkAll ? true : false}
					onChange={() => swtc()}
				></input>
			</div>
		</div>
	]

	let snrs = sensors;
	for (let s in snrs) {
		const snr_id = snrs[s]["id"];
		sensorsList.push(
			<div
				key={snr_id}
				className={classes.row}
				style={{
					color: sensor
						? snr_id === sensor["id"]
							? "#000"
							: "#777"
						: "#777",
					backgroundColor: sensor
						? snr_id === sensor["id"]
							? "#eee"
							: ""
						: "",
				}}
			>
				<div className={classes.cell} style={{ width: "10rem" }}>
					{snrs[s].observedProperty.label}
				</div>
				<div className={classes.cell} style={{ width: "4rem" }}>
					{snrs[s].unit.symbol}
				</div>
				<div className={classes.cell} style={{ width: "6rem" }}>
					{snrs[s].timeInterval}
				</div>
				<div className={classes.cell} style={{ width: "6rem" }}>
					{snrs[s].usedProcedures[0]}
				</div>
				<div className={classes.cell} style={{ width: "16rem" }}>
					{snrs[s].comment}
				</div>
				<div className={classes.cell} style={{ width: "8rem" }}>
					{snrs[s].lastValue
						? `${snrs[s].lastValue.time.substring(8, 10)}-${snrs[
							s
						].lastValue.time.substring(5, 7)}-${snrs[
							s
						].lastValue.time.substring(0, 4)} ${snrs[
							s
						].lastValue.time.substring(11, 13)}:${snrs[
							s
						].lastValue.time.substring(14, 16)}`
						: `---`}
				</div>
				<div className={classes.checkbox} style={{ width: "1rem" }}>
					<input
						type="checkbox"
						checked={
							checkAll
								? true
								: chkSgl.includes(snr_id)
									? true
									: false
						}
						onChange={() => swtcref(snr_id)}
					></input>
				</div>
			</div>
		);
	}

	let sensorsBox = <div className={classes.box}>{sensorsList}</div>;

	let contentPlatforms = <div></div>
	if (deployment && platforms) {
		contentPlatforms =
			deployment.id === "gmt" || deployment.id === "aviator" ? (
				<div style={{ margin: "3rem auto" }}>
					<Button onClick={(e) => download(e)}>Download</Button>
				</div>
			) : platforms ? (
				<div>
					<p className={classes.title}>
						Deployment "{deployment.label}"
					</p>
					{platformsBox}
				</div>
			) : loadingPlatforms ? (
				spinner
			) : (
				<div></div>
			);
	}

	let contentSensors = sensors ? (
		<div>
			<p className={classes.title}>
				Observables measured by "
				{platform ? platform.label : ""}"
			</p>
			{sensorsBox}
		</div>
	) : loadingSensors ? (
		spinner
	) : (
		<div></div>
	);

	const timeSelectAndDownload = platformHover ? (
		<div
			style={{
				pointerEvents: `${checkAll || chkSgl.length ? "" : "none"
					}`,
				opacity: `${checkAll || chkSgl.length ? 1 : 0.4
					}`,
			}}
		>
			<QueryControlsStateless
				minDate={
					platformHover.startDate
						? new Date(platformHover.startDate)
						: new Date(deployment.startDate)
				}
				maxDate={
					platformHover.endDate
						? new Date(platformHover.endDate)
						: new Date()
				}
				onChange={(e) => setQueryControls(e)}
				allowOriginals={isLoggedIn}
			></QueryControlsStateless>
			<Separator orientation="v" size="20" />
			<button
        style={{padding: "0.6rem 1.5rem", fontSize: "0.8rem", fontFamily: "Roboto"}}
				onClick={() => fetchData("download")}
				disabled={
					querySettings
						? querySettings.resolution
							? !loadingData
								? false
								: true
							: true
						: true
				}
			>
				{loadingData ? "Fetching data..." : "Download"}
			</button>
			<Separator orientation="h" size={20} />
			<button
        style={{padding: "0.6rem 1.5rem", fontSize: "0.8rem", fontFamily: "Roboto"}}
				onClick={() => fetchData("plots")}
				btnType="Success"
				disabled={
					querySettings
						? querySettings.resolution
							? !loadingData
								? false
								: true
							: true
						: true
				}
			>
				{loadingData ? "Fetching data..." : "Show Plots"}
			</button>
			<br />
			{loadingData ? spinner : ""}
			<Separator orientation="v" size={50} />
		</div>
	) : (
		<div></div>
	);

	let plots = [];

	plots = data ? (
		data.map((timeseries, index) => {
			return (
				<div className={classes.TimeSeriesCard}>
					<div
						dangerouslySetInnerHTML={{
							__html: platformHover.disclaimer
								? `${Disclaimers[platformHover.disclaimer]}\n\n`
								: "",
						}}
					></div>
					<Card
						key={index}
						title={timeseries.name}
						content={
							<TimeSeries
								series={timeseries}
								key={index}
								display={true}
								cumulative={timeseries.cumulative}
								type="line"
								height='20rem'
							/>
						}
					></Card>
				</div>
			);
		})
	) : (
		<Separator orientation="v" size={100} />
	);

	const title = deployment && platforms ?
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignContent: "center",
				alignItems: "center",
				position: "relative",
				right: "2rem",
			}}
		>
			<div
				style={{
					width: "3rem",
					height: "3rem",
					border: "0px solid grey",
					borderRadius: "50%",
					marginRight: "1rem",
					display: "flex",
					justifyContent: "center",
					boxShadow: "0.05rem 0.1rem 0.4rem 0rem rgba(50,50,50,0.5)",
					cursor: "pointer",
					backgroundColor: "rgb(250,250,250)"
				}}
				onClick={e => backToProjects(e)}>{back}
			</div>
			<h1>Projects</h1></div> :
		<h1>Projects</h1>

	return (
		<div>

			{title}
			{deploymentsBox}
			{contentPlatforms}
			{contentSensors}

			<Separator orientation="v" size="60" />

			{timeSelectAndDownload}
			{showPlots ? plots : ""}
		</div>
	);

}

export default Deployments
