import React from "react";
import classes from "./Card.module.css";

const card = props => {
	return (
		<div
			className={classes.card}
			style={{width: props.width === "auto" ? "" : "100%", ...props.style }}
			onClick={props.click}
		>
			<div
				className={classes.cardContent}
				style={{
					boxShadow:
						props.shadow === "small" ? "0px 2px 12px 2px rgba(0,0,0,0.3)" : "",
					// width: props.width === "auto" ? "auto" : "100%"
				}}
			>
				{/* Header */}
				<div
					className={classes.cardHeader}
					style={{ display: props.title ? "" : "none" }}
				>
					<p className={classes.heading}>{props.title}</p>

					<div
						className={classes.close}
						onClick={props.clickClose}
						style={{ display: props.clickClose ? "" : "none" }}
					>
						<span>×</span>
					</div>
				</div>

				{/* Body */}
				<div
					className={classes.cardBody}
					style={{ textAlign: props.textAlign ? props.textAlign : "" }}
				>
					{props.content}
				</div>

				{/* Footer */}
				<div
					className={classes.cardFooter}
					style={{ display: props.footer ? "" : "none" }}
				>
					{props.footer}
				</div>
			</div>
		</div>
	);
};

export default card;
