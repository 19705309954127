import React from "react";
import classes from "./PerceptualMap.module.css";

const Baloon = props => {
  return (
    <div
        className={classes.baloon}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
    >

      <div
        className={classes.closeCross}
        onClick={(e) => props.click(e)}
      >
        ×
      </div>


      <div style={{ display: "flex", justifyContent: "center", margin: "0rem 1rem 0.3rem 1rem", textAlign: "center" }}>
        <b>{props.title}</b>
      </div>


      <div style={{ margin: "0rem 1rem 0.3rem 1rem" }} dangerouslySetInnerHTML={{ __html: props.comment }} />

      <span style={{ color: "grey", margin: "0rem 1rem 0.3rem 1rem" }}>
        {props.timestamp}
      </span>

      {props.image ?
          <div style={{ margin: "0.3rem 0rem 0rem 0rem", border: "0px solid red" }}>
              {props.image}
          </div>
          :
          ''
      }


    </div>
  );
};

export default Baloon;
