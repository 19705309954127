import React, {useState, Fragment} from 'react'
import Input from "../Input/Input2";
// import Button from "../Button/Button";


const EditUserDataForm = (props) => {

  const [passwords, setPasswords] = useState({password: '', password2: ''})
  const [isValidPassword, setIsValidPassword] = useState(true)
  const [isValidPassword2, setIsValidPassword2] = useState(true)
  
  const submitHandler = async (e) => {
    e.preventDefault()
    props.onSubmit && props.onSubmit(passwords.password)
  }
  
  // const inputChangedHandler = async (e) => {
  //   await setPasswords({...passwords, [e.target.id]: e.target.value})
  // }

  const passwordChangeHandler = async (e) => {

    let isValid = true
    let minLength = 8

    if (e.target.value.trim() === "") {
      isValid = false
    }
  
    if (e.target.value.length < minLength) {
      isValid = false
    }
      
    await setPasswords({...passwords, [e.target.id]: e.target.value})
    
    if (e.target.id === 'password') {
      await setIsValidPassword(isValid)
    }

    if (e.target.id === 'password2') {
      await setIsValidPassword2(isValid && e.target.value === passwords.password)
    }

  }


  return (
    <form onSubmit={submitHandler} style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>

      <Fragment>
        
        <Input
          id='password'
          type='password'
          isValid={isValidPassword}
          placeholder='New password (min 8 chars)'
          onChange={(e) => passwordChangeHandler(e)}
        />

        <Input
          id='password2'
          type='password'
          isValid={isValidPassword2}
          placeholder='Repeat new password'
          onChange={(e) => passwordChangeHandler(e)}
        />

      </Fragment>

      <br />

      <button type='submit' disabled={passwords.password.trim().length && passwords.password2.trim().length && isValidPassword && isValidPassword2 ? false : true}>Save</ button>

    </form>
  )
}

export default EditUserDataForm