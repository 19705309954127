import React from 'react'
import classes from './Input.module.css'

const Input = props => {
  return(

    <input
      className={`${classes.control} ${!props.isValid ? classes.invalid : ''}`}
      type={props.type}
      id={props.id}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.validateHandler}
      placeholder={props.placeholder}
    />

  )
}

export default Input