import React from "react"
import classes from "./LandingPage.module.css"
import { useHistory} from "react-router-dom"


const LandingPage = props => {

  let history = useHistory(); 
  const routeChange = () =>{ 
    let path = `/home`; 
    history.push(path);
  }
	
	return (
		<div className={classes.pageContent} >      

			<div className={classes.ribbon} >
        <div className={classes.logo} >
          <img alt="" src={require('../../imgs/mcri_logo2.svg')} style={{width: "10rem"}} />
        </div>
        
        <div style={{height: "1rem"}}></div>

        Welcome to Manchester-i

          <div style={{height: "2rem"}}></div>
          <div className = {classes.exploreBtn} onClick={routeChange}>
            Explore
          </div>
			</div>
		</div>
	);

}

export default LandingPage;
