import React, { useState } from "react"
import Separator from "../../UI/Separator/separator"

import classes from "./Home.module.css"


const PinsSelector = props => {

  const imgs = {
    traffic: require("../../imgs/sensor_icons/traffic_camera3.svg"),
    noise: require("../../imgs/sensor_icons/noise_monitor3.svg"),
    airquality: require("../../imgs/sensor_icons/air_quality_station3.svg"),
    meteorology: require("../../imgs/sensor_icons/weather_station.svg"),
    hydrology: require("../../imgs/sensor_icons/water_level_station.svg")
  }

  const disciplines = {
    "traffic": "Traffic",
    "airquality": "Air Quality",
    "meteorology": "Meteo",
    "hydrology": "Hydrology"
  }


  const [visibleDisciplines, setVisibleDisciplines] = useState(props.disciplines)

  // useEffect(() => {
  // 	props.onClick(visibleDisciplines)
  // }, [visibleDisciplines])

  const handleClick = e => {

    let disciplines = new Set(visibleDisciplines)
    disciplines.has(e.target.id)
      ? disciplines.delete(e.target.id)
      : disciplines.add(e.target.id)

    setVisibleDisciplines(disciplines)
    props.onClick(disciplines)
  }


  const items = Object.keys(disciplines).map((d, index) => {
    return (
      <div
        key={index}
        className={classes.toggleBtn}
        style={{
          textDecoration: visibleDisciplines ? (visibleDisciplines.has(d) ? 'none' : 'line-through') : 'none'
        }}
        id={d}
        onClick={(e) => handleClick(e)}
      >
        <img
          alt=""
          src={imgs[d]}
          style={{
            width: "1.12rem",
            height: "1.76rem",
            opacity: visibleDisciplines ? (visibleDisciplines.has(d) ? 1 : 0.5) : 0,
          }}
          id={d}
        />

        <Separator orientation="h" size="5" />

        {disciplines[d]}

      </div>
    )
  })


  return (
    <div
      style={{
        display: "flex",
        alignItems: "center"
      }}
    >
      {items}
    </div>
  )

}

export default PinsSelector
