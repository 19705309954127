import React, { useState, useEffect, Fragment } from 'react'
import classes from './barChart.module.css'

const BarChartCust = props => {

  const [maxAnswerValue, setMaxAnswerValue] = useState(0)
  const [answerLabels, setAnswerLabels] = useState([])
  const [answerValues, setAnswerValues] = useState([])
  const [maxOptionValue, setMaxOptionValue] = useState(0)
  const [optionValues, setOptionValues] = useState([])
  const [optionLabels, setOptionLabels] = useState([])
  const [optionColor, setOptionColor] = useState('lightgrey')
  const [showOptions, setShowOptions] = useState(false)
  const [selectedAnswer, setSelectedAnswer] = useState('')


  useEffect(() => {
    if (props.metric) {
      const answerLabels = props.metric.answers.map(a => a.text)
      let answerValues = answerLabels.map(l => 0)
      props.dataPoints.forEach(dp => {
        answerValues[dp.value] += 1
      })
      setAnswerValues(answerValues)
      setAnswerLabels(answerLabels)
      setMaxAnswerValue(Math.max.apply(Math, answerValues))
    }
    else {
      setMaxAnswerValue(0)
    }
  }, [props.dataPoints])


  const onBarClickHandler = (index) => {
    const optionLabels = props.metric.answers[index].options
    setSelectedAnswer(props.metric.answers[index].text)
    let optionValues = optionLabels.map(l => 0)
    props.dataPoints.forEach(dp => {
      if (dp.value === index) {
        const optionIndex = optionLabels.indexOf(dp.comment)
        const otherIndex = optionLabels.indexOf('other')
        optionValues[optionIndex >= 0 ? optionIndex : otherIndex] += 1
      }
    })

    setShowOptions(true)
    setOptionLabels(optionLabels)
    setOptionValues(optionValues)
    setMaxOptionValue(Math.max.apply(Math, optionValues))
    setOptionColor(props.metric.answers[index].colour)

  }

  const goBack = async () => {
    setShowOptions(false)
  }


  const bars = answerValues.map((v, index) => {
    return (
      <div
        key={index}
        className={classes.bar}
        style={{
          width: v === 0 ? '100%' : `calc(100% / (${maxAnswerValue}/${v}))`,
          height: `calc((100% - 3rem) / (${answerValues.length} + 1))`,
          top: `calc(2rem + (100%  - 2rem - 1.3*(100% - 3rem) / (${answerValues.length} + 1)) / ${answerValues.length - 1} * ${index})`,
          backgroundColor: v === 0 ? 'transparent' : props.colors[index],
        }}
        onClick={() => { onBarClickHandler(index) }}
      >
        <div style={{ margin: "0.5rem", fontSize: "small" }}>{answerLabels[index]} ({answerValues[index]})</div>
      </div>
    )
  })

  const bars2 = optionValues.map((v, index) => {
    return (
      <div
        key={index}
        className={classes.bar}
        style={{
          width: v === 0 ? '100%' : `calc(100% / (${maxOptionValue}/${v}))`,
          height: `calc((100% - 1rem) / (${optionValues.length} + 1))`,
          top: `calc(2rem + (100% - 1rem - 1.3*(100% - 2rem) / (${optionValues.length} + 1)) / ${optionValues.length - 1} * (${index}))`,
          backgroundColor: v === 0 ? 'transparent' : optionColor,
        }}
      >
        <div style={{ margin: "0.5rem", fontSize: "small" }}>{optionLabels[index]} ({optionValues[index]})</div>
      </div>
    )
  })


  return (
    <div className={classes.chartBox}>
      
      {props.metric ?
        <Fragment>
          <div
            className={classes.chartArea}
            style={{
              opacity: showOptions ? 1 : 0,
              transform: showOptions ? `translate(50%, -50%)` : `translate(150%, -50%)`,
            }}
            onClick={() => goBack()}
          >
            <div style={{ height: "2rem", width: "100%", fontWeight: "600" }}>{selectedAnswer}</div>
            {bars2}
          </div>

          <div
            className={classes.chartArea}
            style={{
              opacity: showOptions ? 0 : 1,
              transform: showOptions ? `translate(-50%, -50%)` : `translate(50%, -50%)`,
            }}>
            <div style={{ height: "2rem", width: "100%", fontWeight: "600" }}>{props.metric ? props.metric.shortQuestion : ''}</div>
            {bars}
          </div>
        </Fragment>
        :
        <div
          style={{
            margin: "auto",
            fontSize: "large",
            padding: "2rem",
            boxSizing: "borderBox"
          }}> Select the report from the dropdown menu below to see the relevant chart</div>
      }

      <div
        className={classes.closeCross}
        onClick={() => props.onClose()}
      >
        ×
      </div>
    </div>



  )

}

export default BarChartCust;