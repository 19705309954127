import React, { useState, useEffect, useContext, useRef } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import axios from "axios";
import * as gv from "../../global_variables";
import PinsSelector from "./pinsSelector";
import InfoBox from "./infoBox";
import classes from "./Home.module.css";
import MetaTags from "react-meta-tags";
import AuthContext from "../../store/auth-context";
import MapGL, { Marker, NavigationControl, ScaleControl, GeolocateControl, Source, Layer } from 'react-map-gl';
import PostcodeSearch from "../../components/PostcodeSearch/PostcodeSearch";
import useSupercluster from 'use-supercluster';


const Home = () => {

  const authCtx = useContext(AuthContext);


  //====== STATE VARIABLES ======//

  const [viewport, setViewport] = useState({
    latitude: 53.470758,
    longitude: window.innerWidth >= 1000 ? -2.27 : -2.25,
    zoom: 11,
    movingMethod: "flyTo"
  });
  const [showInfoBox, setShowInfoBox] = useState(false);
  const [showPlot, setShowPlot] = useState(false);
  const [showBaloon, setShowBaloon] = useState(false);
  const [plt_sel, setPlt_sel] = useState(null);
  const [visibleDisciplines, setVisibleDisciplines] = useState(new Set([
    "airquality",
    "noise",
    "meteorology",
    "traffic",
    "hydrology",
  ]));
  const [platforms, setPlatforms] = useState([]);
  const [deployments, setDeployments] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [dpls_sel, setDpls_sel] = useState([]);
  const [postcode, setPostcode] = useState([]);
  const [feature, setFeature] = useState(null);


  //====== EFFECTS ======//

  useEffect(async () => {
    // Handler to call on window resize
    function handleResize() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Stuff to be done after the component is loaded
    await getDeployments();
    const res = await axios.get(`${gv.backend_url}/allplatforms`, config);
    const platforms1 = res.data.member;
    console.log(platforms1.length);
    const platforms = res.data.member.filter(p => 'geometry' in p.centroid && p.centroid.geometry.coordinates[0] !== null);
    console.log(platforms.length);
    await setPlatforms(platforms);
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  const mapRef = useRef();

  const config = {
    headers: {
      Authorization: "Bearer " + authCtx.token,
    }
  };


  //====== GLOBAL VARIABLES ======

  const target = require('../../imgs/target.svg');

  const imgs = {
    "Traffic camera": require("../../imgs/sensor_icons/traffic_camera3.svg"),
    "Automatic Traffic Counter": require("../../imgs/sensor_icons/traffic_camera3.svg"),
    "Automatic Cycle Counter": require("../../imgs/sensor_icons/traffic_camera3.svg"),
    "Count units": require("../../imgs/sensor_icons/traffic_camera3.svg"),
    "Noise monitor": require("../../imgs/sensor_icons/noise_monitor3.svg"),
    "Air-quality station": require("../../imgs/sensor_icons/air_quality_station3.svg"),
    "Air-quality monitor": require("../../imgs/sensor_icons/air_quality_station3.svg"),
    "Air-quality device": require("../../imgs/sensor_icons/air_quality_station3.svg"),
    "Meteo station": require("../../imgs/sensor_icons/weather_station.svg"),
    "Meteo monitor": require("../../imgs/sensor_icons/weather_station.svg"),
    "Meteo device": require("../../imgs/sensor_icons/weather_station.svg"),
    "Generic pin": require("../../imgs/sensor_icons/generic_pin.svg"),
    "Generic pin red": require("../../imgs/sensor_icons/generic_pin_red.svg"),
    "River-level station": require("../../imgs/sensor_icons/water_level_station.svg"),
  };

  const disciplines = {
    "Air-quality station": "airquality",
    "Air-quality monitor": "airquality",
    "Air-quality device": "airquality",
    "Meteo station": "meteorology",
    "Meteo monitor": "meteorology",
    "Meteo device": "meteorology",
    "Traffic camera": "traffic",
    "Automatic Traffic Counter": "traffic",
    "Automatic Cycle Counter": "traffic",
    "River-level station": "hydrology",
    "Noise monitor": "noise",
  };

  const spinner = (
    <img
      alt=""
      src={require("../../imgs/spinner.svg")}
      style={{ width: "3.35rem", margin: "2.5rem auto" }}
    />
  );


  //====== Functions ======//

  const zoomOnPostcode = async (e, polygon) => {
    await setViewport({
      ...viewport,
      latitude: e.latLng[0],
      longitude: e.latLng[1],
      zoom: 17,
      movingMethod: 'flyTo'
    });
    await setPostcode([e.latLng[0], e.latLng[1]]);
    console.log(polygon);
    await setFeature(polygon);
  };


  // const resetMapView = async (e) => {
  //   await setViewport({
  //     ...viewport,
  //     longitude: window.innerWidth >= 1000 ? -2.27 : -2.25,
  //     latitude: 53.480758,
  //     zoom: 11,
  //     movingMethod: "flyTo"
  //   });
  //   await setShowInfoBox(false);
  //   await setShowPlot(false);
  //   await setShowBaloon(false);
  //   await setPlt_sel(null);
  //   await setVisibleDisciplines(new Set([
  //     "airquality",
  //     "noise",
  //     "meteorology",
  //     "traffic",
  //     "hydrology",
  //   ]));
  // };


  const getDeployments = async () => {
    const res = await axios.get(`${gv.backend_url}/mongo/deployments`, config);
    await setDeployments(res.data);
  };


  const populateInfoBox = async (e, platform_id) => {
    const platform = platforms.filter(p => p.identifier === platform_id)[0];
    setViewport({
      ...viewport,
      latitude: windowWidth >= 1000 ? platform.centroid.geometry.coordinates[1] : platform.centroid.geometry.coordinates[1] - 0.00007,
      longitude: platform.centroid.geometry.coordinates[0],
      zoom: 20,
      movingMethod: "flyTo"
    });
    setShowBaloon(true);
    const plt_id = platform.identifier;
    const dpls_sel = deployments.filter((dpl) =>
      dpl.deployedOnPlatform.includes(plt_id)
    );
    await setDpls_sel(dpls_sel);
    await setShowInfoBox(true);
    await setPlt_sel(platform);
  };

  const mapClicked = async (e) => {
    await setShowInfoBox(false);
    await setShowPlot(false);
    await setShowBaloon(false);
  };

  const setVisibility = async (visibleDisciplines) => {
    await setVisibleDisciplines(visibleDisciplines);
  };


  const visiblePlatforms = platforms.filter(p => visibleDisciplines.has(disciplines[p.description]));

  const points = visiblePlatforms
    ?
    visiblePlatforms.map(p => {
      return {
        "type": "Feature",
        "geometry": p.centroid.geometry,
        "properties": {
          "cluster": false,
          "diclipline": disciplines[p.description],
          "id": p.identifier,
          "description": p.description
        }
      };
    })
    :
    [];


  // get map bounds
  const bounds = mapRef.current
    ? mapRef.current
      .getMap()
      .getBounds()
      .toArray()
      .flat()
    : null;


  // get clusters
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom: viewport.zoom,
    options: { radius: 75, maxZoom: 20 }
  });


  //------ Descriptive ballon for selected pin ------//
  let baloonCont = (
    <div className={classes.baloon} style={{ display: showBaloon ? "" : "none" }}>
      <span style={{ fontWeight: "600" }}>Platform:</span>
      <br />
      {plt_sel
        ? `${plt_sel.description} (${plt_sel.label})`
        : ""}
      <br />
      <br />
      <span style={{ fontWeight: "600" }}>Deployed on:</span>
      <br />
      {dpls_sel ? (
        <ul
          style={{
            margin: "0.2rem 0rem 0rem 0rem",
            listStyleType: "dot",
            listStylePosition: "outside",
            paddingLeft: "1.1rem",
          }}
        >
          {dpls_sel.map((dpl) => (
            <li style={{ margin: "0rem" }} key={dpl.label}>
              <span style={{ position: "relative", left: "-0.4rem" }}>
                {dpl.label}
              </span>
            </li>
          ))}
        </ul>
      ) : (
        ""
      )}
    </div>
  );


  let controls =
    <div
      className={classes.controls}
      style={{ display: windowWidth > 1000 ? "" : "none" }}
    >
      <PinsSelector
        onClick={(e) => setVisibility(e)}
        disciplines={visibleDisciplines}
      />
      {/*{resetView}*/}

    </div>;


  const layerStyle = {
    id: 'polygon',
    type: 'fill',
    paint: {
      'fill-color': feature ? 'purple' : 'transparent',
      'fill-opacity': 0.1
    }
  };

  const pippo = <Source id="my-data" type="geojson" data={feature}>
    <Layer {...layerStyle} />
  </Source>;


  const resetPostcode = async () => {
    await setFeature(null);
    await setPostcode([]);
  };


  return (
    <div>

      <MetaTags>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0"
        />
        <meta
          name="display"
          content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0"
        />
      </MetaTags>

      <div
        style={{
          zIndex: 0,
          width: windowWidth > 1000 ? "100%" : windowWidth,
          height: windowHeight
        }}
      >

        <MapGL
          ref={mapRef}
          {...viewport}
          width="100%"
          height="100%"
          movingMethod="flyTo"
          onViewportChange={viewport => setViewport(viewport)}
          onNativeClick={e => mapClicked()}
          mapboxApiAccessToken="pk.eyJ1IjoiZXR0b3JlbXVyYWJpdG8iLCJhIjoiY2pvaGM4bHNqMGh0ejNwb2FiYjBoemplOSJ9.qUwd_2Vm07oj5L8jq2XX-w"
          // mapStyle="mapbox://styles/mapbox/bright-v9"
          mapStyle="mapbox://styles/ettoremurabito/cky4yo7nj5s2f15pcivxqchw2"
          scrollZoom={{ "smooth": true }}
          attributionControl={false}
          mapbox-logo={false}
          logoPosition='bottom-right'
        >
          {windowWidth >= 1000 ?
            <Marker
              latitude={plt_sel ? plt_sel.centroid.geometry.coordinates[1] : viewport.latitude}
              longitude={plt_sel ? plt_sel.centroid.geometry.coordinates[0] : viewport.longitude}
              style={{ display: showBaloon ? "" : "none" }}
            >
              {baloonCont}
            </Marker>
            : ''}

          {points.length ?
            clusters.map((cluster, i) => {
              // every cluster point has coordinates
              const [longitude, latitude] = cluster.geometry.coordinates;
              // the point may be either a cluster or a crime point
              const {
                cluster: isCluster,
                point_count: pointCount
              } = cluster.properties;

              // we have a cluster to render
              if (isCluster) {
                return (
                  <Marker
                    key={`cluster-${cluster.id}`}
                    latitude={latitude}
                    longitude={longitude}
                  >
                    <div
                      className={classes.clusterMarker}

                      style={{
                        width: `${10 + (pointCount / points.length) * 60}px`,
                        height: `${10 + (pointCount / points.length) * 60}px`
                      }}

                      onClick={() => {
                        const expansionZoom = Math.min(
                          supercluster.getClusterExpansionZoom(cluster.id),
                          20
                        );

                        setViewport({
                          ...viewport,
                          latitude,
                          longitude,
                          zoom: expansionZoom,
                          movingMethod: "flyTo"
                        });
                      }}

                    >

                      {pointCount}
                    </div>
                  </Marker>
                );
              }

              // we have a single point (crime) to render
              return (
                <Marker
                  key={`crime-${i}`}
                  latitude={latitude}
                  longitude={longitude}
                >

                  <img
                    alt=""
                    src={
                      imgs[cluster.properties.description]
                        ? imgs[cluster.properties.description]
                        : imgs["Generic pin red"]}
                    style={{
                      width: "1.4rem",
                      height: "2.2rem",
                      cursor: "pointer",
                      transform: 'translate(-50%, -100%)'
                    }}
                    onClick={async (e) => {
                      populateInfoBox(e, cluster.properties.id);
                    }}
                  />

                </Marker>
              );


            })
            : <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)" }}>{spinner}</div>}

          {postcode.length ? <Marker latitude={postcode[0]} longitude={postcode[1]}><img alt="" src={target} style={{ position: "absolute", top: "50%", left: "50%", width: "4rem", transform: "translate(-50%,-50%)" }} /></Marker> : ''}

          <NavigationControl
            style={{ position: "absolute", bottom: "4rem", right: "1rem" }}
          />
          <ScaleControl style={{ position: "absolute", bottom: "0rem", right: "1rem" }} />
          <GeolocateControl
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation={true}
            style={{ position: "absolute", bottom: "1rem", right: "1rem" }}
          />

          {pippo}

        </MapGL>

        {controls}

        <PostcodeSearch
          onPostcodeFound={zoomOnPostcode}
          resetPostcode={resetPostcode}
        />

        <InfoBox
          showInfoBox={showInfoBox}
          showPlot={showPlot}
          platform={plt_sel}
          closeInfoBox={mapClicked}
        />

      </div>

    </div>
  );

};


export default Home;