import React from 'react'
import Chart from 'react-apexcharts'


const TimeSeries = props => {

  const series = [props.series]
  const type = props.series['cumulative'] === true ? 'area' : 'line'

  const options = {
    chart: {
      height: '100%',
      zoom: {
        enabled: true
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 10,
        animateGradually: {
            enabled: false
        },
        dynamicAnimation: {
            enabled: true,
            speed: 150
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight',
      width: 2
    },
    title: {
      text: props.title,
      align: 'center'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    markers: {
      size: 3,
      strokeWidth: 0
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeFormatter: {
          year: 'yyyy',
          month: 'MMM \'yy',
          day: 'dd MMM',
          hour: 'HH:mm'
        }
      }
    },
    yaxis : {
      title: {
        text: props.series.label
      }
    },
    tooltip: {
      x: {
        show: true,
        format: `dd MMM 'yy - HH:mm`,
        formatter: undefined,
      }
    },
    // fill: {
    //   type: 'solid'
    // }
  }


  return (
    <div style = {{height: props.height}}>
      <Chart options={options} type = {type} series={series} width={'100%'} height={'100%'} />
    </div>
  )
}

export default TimeSeries;