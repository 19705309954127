import React, {useState, Fragment} from 'react'
import Input from "../Input/Input2";
// import Button from "../Button/Button";


const LoginForm = (props) => {

  const [userData, setUserData] = useState({email: null, password: null})
  const [isValidEmail, setIsValidEmail] = useState(true)
  const [isValidPassword, setIsValidPassword] = useState(true)

  const inputChangedHandler = async (e) => {
    await setUserData({...userData, [e.target.id]: e.target.value})
  };
  
  const submitHandler = async (e) => {
    e.preventDefault()
    props.onSubmit(userData)
  }
  
  
  const emailChangeHandler = async (e) => {
    console.log(e.target.value)
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    await setIsValidEmail(pattern.test(e.target.value))
    await setUserData({...userData, [e.target.id]: e.target.value})
  }

  
  const passwordChangeHandler = async (e) => {
    let isValid = true
    let minLength = 8
    if (e.target.value.trim() === "") {
      isValid = false
    }
  
    if (e.target.value.length < minLength) {
      isValid = false
    }
  
    await setUserData({...userData, [e.target.id]: e.target.value})
    await setIsValidPassword(isValid)
  }
  

  return (
    <form onSubmit={submitHandler} style={{ display: "flex", flexFlow: "column wrap", alignItems: "center" }}>

      {props.signup ?

        <Fragment>
          
          <Input
            id='firstName'
            type='text'
            isValid={true}
            placeholder='First name *'
            onChange={(e) => inputChangedHandler(e)}
          />

          <Input
            id='lastName'
            type='text'
            isValid={true}
            placeholder='Last name *'
            onChange={(e) => inputChangedHandler(e)}
          />

          <Input
            id='affiliation'
            type='text'
            isValid={true}
            placeholder='Affiliation'
            onChange={(e) => inputChangedHandler(e)}
          />

        </Fragment>
        : 
        <Fragment />
      }

      <Input
        id='email'
        type='email'
        isValid={isValidEmail}
        placeholder={props.signup ? 'Email *' : 'Email'}
        onChange={(e) => props.signup ? emailChangeHandler(e) : inputChangedHandler(e)}
      />

      <Input
        id="password"
        type="password"
        isValid={isValidPassword}
        placeholder={props.signup ? "Password (min 8 chars) *" : "Password"}
        onChange={(e) => props.signup ? passwordChangeHandler(e) : inputChangedHandler(e)}
      />

      <br />

      <button 
        type='submit' 
        disabled={props.signup ? !(isValidEmail & isValidPassword) : false}
        style={{fontSize: "0.9rem", padding:"0.7rem 1.5rem"}}
      >{props.signup ? `Create Account` : `Login`}
        
      </button>

    </form>
  )
}

export default LoginForm