import React from "react";
import LandingPage from "../Pages/LandingPage/LandingPage";

const LandingLayout = () => {
	return (

    <div style = {{
        position: "absolute",
        top: "0rem",
        left: "0rem",
        boxSizing: "border-box",
        padding: "0rem",
        backgroundColor: "inherit",
        height: "100%",
        width: "100%",
        border: "0px solid red"  
    }}>
    <LandingPage />
    </div>
	);
}

export default LandingLayout;