import React, { useState, useEffect, useContext, Fragment } from "react"
import classes from "./NavBar.module.css"
import NavBarItem from "./NavBarItem/NavBarItem"
import * as links from "./items"
import AuthContext from "../store/auth-context"

const NavBar = props => {

  const authCtx = useContext(AuthContext)
  const isLoggedIn = authCtx.isLoggedIn
  const user = authCtx.user


  const [windowW, setWindowW] = useState(window.innerWidth)
  const [visible, setVisible] = useState(window.innerWidth < 1000 ? false : true)
  const [submenu, setSubmenu] = useState(false)

  useEffect(async () => {
    // Handler to call on window resize
    function handleResize() {
      setWindowW(window.innerWidth)
      setVisible(window.innerWidth < 1000 ? false : true)
    }

    // Add event listener
    window.addEventListener("resize", handleResize)

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount


  const hideMenu = () => {
    setVisible(false)
  }

  const showMenu = () => {
    setVisible(true)
  }

  const toggleSubmenu = () => {
    setSubmenu(!submenu)
  }


  return (

    <div
      className={visible ? classes.NavBar : classes.NavBarHiddenRight}
    >

      {/* NavBar header */}
      {windowW < 1000 ?

        <Fragment>

          <div className={visible ? classes.ShowMenuExpanded : classes.ShowMenu}
            onClick={showMenu}>
            <img alt="" src={require("../imgs/mcri_logo2.svg")} style={{ width: "2.8rem" }} />
          </div>

          <div
            style={{
              position: "absolute",
              top: "1.8rem",
              left: "0rem",
              padding: "0rem 0rem 0.5rem 1rem",
            }}
            onClick={hideMenu}
          >
            <img
              alt=""
              className={classes.Logo}
              src={require("../imgs/collapseMenuLeft_white.svg")}
              style={{ width: "2.2rem" }}
            />
          </div>

          <div
            style={{
              height: "5.5rem"
            }}
          />
        </Fragment>
        :
        <div style={{ display: "flex", flexDirection: "Column", alignItems: "center", justifyContent: "center", margin: "1rem 0rem 1rem -0.2rem" }}>
          <div style={{ backgroundColor: "#253343", borderRadius: "50%", padding: "1rem", width: "3rem", height: "3rem", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <img alt="" src={require('../imgs/mcri_logo2.svg')} width="60px" />
          </div>
          <p style={{ fontFamily: "'Fedra',sans-serif", marginTop: "0.4rem", marginBottom: "0.5rem", fontWeight: "400", color: "lightgrey" }}>Manchester-i</p>
          <hr style={{ width: "80%", marginLeft: "auto", marginRight: "auto", borderTop: "1px solid #45525f", borderBottom: "1px solid #56636f" }} />
        </div>
      }



      <div onClick={hideMenu}>
        <NavBarItem
          title="Sensors"
          href={"/" + links.NAV_HOME}
          image={require("../imgs/sensors_white.svg")}
        ></NavBarItem>
      </div>


      {windowW >= 1000 ?
        <div onClick={hideMenu}>
          <NavBarItem
            title="Projects"
            href={"/projects"}
            image={require("../imgs/bulb_white.svg")}
          ></NavBarItem>
        </div>
        : null}


      <div onClick={toggleSubmenu}>
        <div className={classes.NavBarItemContainer} >
          <img alt="" src={require("../imgs/crowd_white.svg")} className={classes.NavBarItemIcon} />
          <p className={classes.NavBarItemTitle} >Crowdsourcing</p>
          <div className={submenu ? classes.arrowDown : classes.arrowRight} />
        </div>
      </div>

      <div
        style={{
          height: submenu ? "5rem" : "0rem", transition: "height 0.3s",
          backgroundColor: "#34414e",
          overflowY: "hidden"
        }} >

        <div onClick={hideMenu}>
          <NavBarItem
            title="Have your say"
            href={"/cstwo"}
            image={require("../imgs/vignette_white.svg")}
            submenu={true}
          ></NavBarItem>
        </div>

        <div onClick={hideMenu}>
          <NavBarItem
            title="Results map"
            href={"/pm"}
            image={require("../imgs/map_white.svg")}
            submenu={true}
          ></NavBarItem>
        </div>

      </div>


      {/* {props.isAuth && windowW >= 1000 ? */}
      {
        isLoggedIn && windowW >= 1000 && user &&
        <Fragment>
          {/* <div onClick={hideMenu}>
            <NavBarItem
              title="Faulty Devices"
              href={"/fds"}
              image={require("../imgs/exclamation-mark_white.svg")}
            ></NavBarItem>
          </div> */}

          <div onClick={hideMenu}>
            <NavBarItem
              title="Add/Edit Device"
              href={"/create_and_edit_devices"}
              image={require("../imgs/device_white.svg")}
            ></NavBarItem>
          </div>
        </Fragment>
      }

      <div onClick={hideMenu}>
        <NavBarItem
          title="About us"
          href={"/about_us"}
          image={require("../imgs/aboutus_white.svg")}
        ></NavBarItem>
      </div>


      <div onClick={hideMenu}>
        <NavBarItem
          title="FAQs"
          href={"/faqs"}
          image={require("../imgs/faqs_white.svg")}
        ></NavBarItem>
      </div>

      <div onClick={hideMenu}>
        <NavBarItem
          title="API"
          href={"/api"}
          image={require("../imgs/gear2_white.svg")}
        ></NavBarItem>
      </div>


      {isLoggedIn &&
        <div onClick={hideMenu}>
          <NavBarItem
            title="Your Account"
            href={"/" + links.NAV_USER}
            image={require("../imgs/user_white.svg")}
          ></NavBarItem>
        </div>
      }


      {isLoggedIn ?
        <div onClick={hideMenu}>
          <NavBarItem
            title="Logout"
            href={"/" + links.NAV_LOGOUT}
            image={require("../imgs/logout_white.svg")}
          ></NavBarItem>
        </div>
        :
        <div onClick={hideMenu}>
          <NavBarItem
            title="Login"
            href={"/" + links.NAV_AUTH}
            image={require("../imgs/login_white.svg")}
          ></NavBarItem>
        </div>
      }

    </div>
  )
}


export default NavBar
