import React from "react";
import classes from "../NavBar.module.css";
import { NavLink } from 'react-router-dom';

const NavBarItem = props => {
	return (
		<NavLink
			className = {props.submenu ? classes.NavBarItemSub : classes.NavBarItem}
			activeClassName={props.noActive ? '' : classes.NavBarItemActive}
      to={props.href} exact
      style={{padding: props.padding}}
      onClick = {props.click}
		>
			<img alt="" className={classes.NavBarItemIcon} src={props.image} style={{width: props.imgWidth}} />
			<div className={classes.NavBarItemTitle} style={{fontSize: props.fontSize}}>{props.title}</div>
      
		</NavLink>
	);
}

export default NavBarItem;
