import React, {useEffect, useState} from "react"
import "react-datepicker/dist/react-datepicker.css"
import "react-datepicker/dist/react-datepicker-cssmodules.css"

import Separator from "../Separator/separator"
import classes from "./queryControls.module.css"

const QueryControlsStateless = props => {

  const [startTime, setStartTime] = useState(props.minDate ? props.minDate : props.start_time ? props.start_time : new Date())
  const [endTime, setEndTime] = useState(props.maxDate ? props.maxDate : props.end_time ? props.end_time : new Date().setDate(new Date().getDate() - 1))
  const [resolution, setResolution] = useState(props.resolution)
  const [interpolate, setInterpolate] = useState(props.interpolate ? props.interpolate : false)


  useEffect(async () => {
    props.refreshPlot && await props.refreshPlot()
  }, [])


  useEffect(async () => {
    await props.onChange({start_time: startTime, end_time: endTime, resolution, interpolate})
  }, [startTime, endTime, resolution, interpolate])


	const changeDateTime = async (date, startEnd) => {
		let d = new Date(date.target.value)
		if (isNaN(d.getTime())) {
			console.log(`DATE IS NOT VALID`)
			return
		} 
		if (startEnd === "start") {
			if (props.minDate && d < props.minDate)
				await setStartTime(props.minDate);
			else await setStartTime(d);
		} else if (startEnd === "end") {
			if (d > props.maxDate) {
				await setEndTime(props.maxDate);
			} else await setEndTime(d);
		}
	}


	const handleChangeResolution = async (resolution) => {
		await setResolution(resolution.target.value);
	}


	const toggleInterpolate = async (interpolate) => {
		await setInterpolate(interpolate.target.checked);
	}


	let timeWindow = endTime - startTime;

  let selectResolution = (
    <select
      className={classes.InputField}
      onChange={(e) => handleChangeResolution(e)}
      style={{ display: "inline" }}
    >
      <option value="">Select</option>
      {props.allowOriginals ? 
      <option
        value="originals"
        selected={props.resolution === "originals" ? true : false}
      >
        originals
      </option>
      : ''}
      <option
        value="5m"
        disabled={timeWindow / (60000 * 5) > 2000 ? true : false}
        selected={props.resolution === "5m" && timeWindow / (60000 * 5) <= 2000 ? true : false}
      >
        5 mins
      </option>
      <option
        value="15m"
        disabled={timeWindow / (60000 * 15) > 2000 ? true : false}
        selected={props.resolution === "15m" && timeWindow / (60000 * 15) <= 2000? true : false}
      >
        15 mins
      </option>
      <option
        value="30m"
        disabled={timeWindow / (60000 * 30) > 2000 ? true : false}
        selected={props.resolution === "30m" && timeWindow / (60000 * 30) <= 2000 ? true : false}
      >
        30 mins
      </option>
      <option
        value="1h"
        disabled={timeWindow / (60000 * 60) > 2000 ? true : false}
        selected={props.resolution === "1h" && timeWindow / (60000 * 60) <= 2000 ? true : false}
      >
        1 hour
      </option>
      <option
        value="3h"
        disabled={timeWindow / (60000 * 60 * 3) > 2000 ? true : false}
        selected={props.resolution === "3h" && timeWindow / (60000 * 60 *3) <= 2000 ? true : false}
      >
        3 hour
      </option>
      <option
        value="6h"
        disabled={timeWindow / (60000 * 60 * 6) > 2000 ? true : false}
        selected={props.resolution === "6h" && timeWindow / (60000 * 60 * 6) <= 2000 ? true : false}
      >
        6 hours
      </option>
      <option
        value="12h"
        disabled={timeWindow / (60000 * 60 * 12) > 2000 ? true : false}
        selected={props.resolution === "12h" && timeWindow / (60000 * 60 * 12) <= 2000 ? true : false}
      >
        12 hours
      </option>
      <option
        value="1d"
        disabled={timeWindow / (60000 * 60 * 24) > 2000 ? true : false}
        selected={props.resolution === "1d" && timeWindow / (60000 * 60 * 24) <= 2000? true : false}
      >
        1 day
      </option>
      <option value="1w, 4d">1 week</option>
    </select>
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "inline-block",
          textAlign: "left",
          fontSize: "small",
        }}
      >
        From
        <Separator orientation="h" size="5" />
        <input 
          type="datetime-local"
          className={classes.datePicker}
          onChange={(date) => changeDateTime(date, "start")}
          value = {startTime.toISOString().substring(0,16)}
        />
      </div>

      <Separator orientation="h" size="20" />

      <div
        style={{
          display: "inline-block",
          textAlign: "left",
          fontSize: "small",
        }}
      >
        To
        <Separator orientation="h" size="5" />
        <input 
          type="datetime-local"
          className={classes.datePicker}
          onChange={(date) => changeDateTime(date, "end")}
          value = {endTime.toISOString().substring(0,16)}
        />
      </div>

      <Separator orientation="h" size="20" />

      <div
        style={{
          display: "inline-block",
          textAlign: "left",
          fontSize: "small",
        }}
      >
        Res
        <Separator orientation="h" size="5" />
        {selectResolution}
      </div>

      <Separator orientation="h" size="30"></Separator>
      {props.button}
      <Separator orientation="v" size="20"></Separator>

      <div>
        <input
          type="checkbox"
          value="or"
          checked={props.interpolate}
          className={classes.CheckBox}
          onChange={(e) => toggleInterpolate(e)}
        />
        <p
          style={{
            verticalAlign: "middle",
            display: "inline",
            fontSize: "small",
          }}
        >
          Interpolate
        </p>
      </div>

      <Separator orientation="v" size="20"></Separator>
    </div>
  );

}

export default QueryControlsStateless;
