import React from 'react';

import classes from './API.module.css';
 
 
const API = props => {


  return (
    <div className = {classes.pageContent}>

      <h1>API</h1>

      <div className = {classes.tc}>
      
        <p>
          The API provided by Manchester-i offers a range of endpoints that enable programmatic access to urban data
          collected and exposed by the platform.
        </p>

        <p>
          All of these endpoints <b><i>can</i></b> be queried passing a Bearer Token (although this is not necessary) 
          that the user can obtain by first authenticating. The authenticated user will be able to see extra data that
          otherwise would not be accessible. The extra data made available to authenticated users depend on the users'
          role within Manchester-i.
        </p>

        <p>
          To see the full API documentation and try out its endpoints, please click the button below.
        </p>
        <p style={{display: "flex", justifyContent: "center"}}>
        <a href="https://documenter.getpostman.com/view/826921/2s93m4XhpC" 
          target="blank">
          <button style={{fontSize: "0.9rem", padding: "0.7rem 2rem", margin: "2rem auto"}}>Full API documentation</button>
        </a>
        </p>

        <hr style={{margin: "1.5rem auto"}} />
        
        <details style={{fontSize:"0.9rem"}}>
          <summary style={{fontSize:"1rem", fontWeight:"600", cursor: "pointer"}}>Read more...</summary>
          <div style={{color: "#222", padding: "1rem", boxSizing: "border"}}>
          <p>
            In this section we present the main concepts that describe how urban data is structured and exposed
            through Manchester-i, without going into too much detail.
          </p>
          <p>Manchester-i hosts data that pertain to different projects and initiatives. Each of these projects generates 
            data through a number of sensing devices deployed to fulfil the project's aims. In this API, projects are 
            called <i>deployemnts</i> and the sensing devices are called <i>platforms</i>. It should be noted that a sensing
            device (platform) could be something as simple as a Raspberry Pi or as complex as a DEFRA Air Quality monitoring 
            station.
          </p>
          <p>
            Each platform hosts one or more <i>sensors</i>. For example a DEFRA Air Quality monitoring station can host a 
            sensor for particulate matter, a sensor for CO<sub>2</sub>, a sensor for temperature, etc.
          </p>
          <p>
            Each sensor can generate one or more data streams. For example a sensor for particulate matter can generate a data
            stream for PM<sub>10</sub>, a data stream for PM<sub>2.5</sub>, etc. In Manchester-i data streams are called <i>time series</i>. 
          </p>
          <p>Each time series is a collection of individual <i>observations</i>, each one of them consisting of a numerical
            value and a timestamp.
          </p>
          <p>
            The picture below depicts the hierarchical structure of these concepts.
          </p>

          <img alt="" src={require("../../imgs/schema.png")} style={{maxWidth: "inherit", width: "100%"}}/>

          <p>
            What follows includes a more formal definition of the concepts mentioned earlier, along with additional
            concepts essential for their complete characterization.
          </p>

          <p>
            <b>Deployment</b> - <i>A set of platforms employed to meet the needs of a given project or initiative.</i>
          </p>

          <p>
            <b>Platform</b> - <i>An entity that hosts other entities, particularly Sensors, Actuators, Samplers, 
            and other Platforms. In the context of Manchester-i, a platform is generally a sensing device hosting
            one of more sensors.</i>
          </p>

          <p>
            <b>Sensor</b> - <i>A device or agent (including humans) that performs the act of measuring some 
            observable property. Sensors can be (and usually are) hosted by Platforms. An example is the particulate 
            matter sensor hosted by an air-quality monitoring station (the platform).</i>
          </p>

          <p>
            <b>ObservableProperty</b> - <i>An observable quality, property or characteristic of a FeatureOfInterest (see below).
            An example is the outdoor air temperature, where the temperature is the ObservableProperty and the air the 
            FeatureOfInterest.</i>
          </p>

          <p>
            <b>Observation</b> - <i>A single reading of an observable property made by a sensor.</i>
          </p>

          <p>
            <b>Timeseries</b> - <i>A collection of observations made by the same sensor and referring to the same 
            observable property.</i>
          </p>

          <p>
            <b>FeatureOfInterest</b> - <i>The thing whose property is being estimated, calculated or measured.</i>
          </p>

          <p>
            <b>Units</b> - <i>The unit of measurements used to describe the numerical value of an Observation.</i>
          </p>

          <p>
            <b>Discipline</b> - <i>The discipline, or field of investigation, that an observation refers to. For example,
            the observations made by a particulate matter sensor pertain to the discipline “atmospheric chemistry”.</i>
          </p>

          <p>
            <b>Location</b> - <i>The location of a platform, described as a geoJson object.</i>
          </p>

          {/* <img alt="" src={require("../../imgs/Metadata.png")} style={{maxWidth: "inherit", width: "100%"}}/> */}


          </div>
        </details>


        

        

        <div style={{height: "5rem"}} />
        


        {/* {documentation ? (
          <SwaggerUI spec={documentation} />
        ) : (
          <p>Loading Swagger UI...</p>
        )} */}
      </div>
    </div>
  );

}

export default API;
