const DevInfo = {
  "arisense": {
    label: "AriSense",
    disciplines: ["atmospheric-chemistry", "meteorology"],
    comment: ["outdoor", "indoor"],
    type: "Air-quality device",
    sensors: [
      {
        "gas-sensor": {
          observables: [
            { id: "co-mass-concentration", disciplines: ["atmospheric-chemistry"], usedProcedures: ["average"], unit: "parts-per-billion" },
            { id: "co2-mass-concentration", disciplines: ["atmospheric-chemistry"], usedProcedures: ["average"], unit: "parts-per-billion" },
            { id: "no-mass-concentration", disciplines: ["atmospheric-chemistry"], usedProcedures: ["average"], unit: "parts-per-billion" },
            { id: "no2-mass-concentration", disciplines: ["atmospheric-chemistry"], usedProcedures: ["average"], unit: "parts-per-billion" }
          ],
          timeInterval: "1 min",
          label: "Gas sensor"
        }
      },
      {
        "ozone-sensor": {
          observables: [
            { id: "o3-mass-concentration", disciplines: ["atmospheric-chemistry"], usedProcedures: ["average"], unit: "parts-per-billion" }
          ],
          timeInterval: "1 min",
          label: "Ozone sensor"
        }
      },
      {
        "opc": {
          observables: [
            { id: "pm1-mass-concentration", disciplines: ["atmospheric-chemistry"], usedProcedures: ["average"], unit: "microgram-per-cubic-metre" },
            { id: "pm2p5-mass-concentration", disciplines: ["atmospheric-chemistry"], usedProcedures: ["average"], unit: "microgram-per-cubic-metre" },
            { id: "pm10-mass-concentration", disciplines: ["atmospheric-chemistry"], usedProcedures: ["average"], unit: "microgram-per-cubic-metre" }
          ],
          timeInterval: "1 min",
          label: "Optical Particle Counter"
        }
      },
      {
        "hygrometer": {
          observables: [
            { id: "air-temperature", disciplines: ["meteorology"], usedProcedures: ["average"], unit: "degree-celsius" },
            { id: "relative-humidity", disciplines: ["meteorology"], usedProcedures: ["average"], unit: "percent" }
          ],
          timeInterval: "1 min",
          label: "Hygrometer"
        }
      },
      {
        "barometer": {
          observables: [{ id: "air-pressure", disciplines: ["meteorology"], usedProcedures: ["average"], unit: "pascal" }],
          timeInterval: "1 min",
          label: "Barometer"
        }
      },
      {
        "anemometer": {
          observables: [
            { id: "wind-speed", disciplines: ["meteorology"], usedProcedures: ["average"], unit: "metre-per-second" },
            { id: "wind-direction", disciplines: ["meteorology"], usedProcedures: ["average"], unit: "degree" }
          ],
          timeInterval: "1 min",
          label: "Anemometer"
        }
      }
    ]
  },

  "vivacity": {
    label: "VivaCity",
    disciplines: ["transport"],
    type: "Traffic camera",
    duplicate: [
      { id: "N", label: "northbound" },
      { id: "S", label: "southbound" },
      { id: "E", label: "eastbound" },
      { id: "W", label: "westbount" },
      { id: "NE", label: "northeast bound" },
      { id: "NW", label: "northwest bound" },
      { id: "SE", label: "southeast bound" },
      { id: "SW", label: "southwest bound" }
    ],
    sensors: [
      {
        "camera_lens": {
          timeInterval: "5 mins",
          observables: [
            { id: "people-count", qualifier: {id: "pedestrian", label: "Pedestrians"}, disciplines: ["transport"], usedProcedures: ["count"], unit: "count" },
            { id: "vehicle-count", qualifier: {id: "cyclist", label: "Cyclists"}, disciplines: ["transport"], usedProcedures: ["count"], unit: "count" },
            { id: "vehicle-count", qualifier: {id: "motorbike", label: "Motorbikes"}, disciplines: ["transport"], usedProcedures: ["count"], unit: "count" },
            { id: "vehicle-count", qualifier: {id: "car", label: "Cars"}, disciplines: ["transport"], usedProcedures: ["count"], unit: "count" },
            { id: "vehicle-count", qualifier: {id: "taxi", label: "Taxis"}, disciplines: ["transport"], usedProcedures: ["count"], unit: "count" },
            { id: "vehicle-count", qualifier: {id: "van", label: "Vans"}, disciplines: ["transport"], usedProcedures: ["count"], unit: "count" },
            { id: "vehicle-count", qualifier: {id: "minibus", label: "Minibuses"}, disciplines: ["transport"], usedProcedures: ["count"], unit: "count" },
            { id: "vehicle-count", qualifier: {id: "bus", label: "Buses"}, disciplines: ["transport"], usedProcedures: ["count"], unit: "count" },
            { id: "vehicle-count", qualifier: {id: "rigid", label: "Rigids"}, disciplines: ["transport"], usedProcedures: ["count"], unit: "count" },
            { id: "vehicle-count", qualifier: {id: "truck", label: "Trucks"}, disciplines: ["transport"], usedProcedures: ["count"], unit: "count" },
            { id: "vehicle-count", qualifier: {id: "emergency_car", label: "Emergency cars"}, disciplines: ["transport"], usedProcedures: ["count"], unit: "count" },
            { id: "vehicle-count", qualifier: {id: "emergency_van", label: "Emergency vans"}, disciplines: ["transport"], usedProcedures: ["count"], unit: "count" },
            { id: "vehicle-count", qualifier: {id: "fire_engine", label: "Fire engines"}, disciplines: ["transport"], usedProcedures: ["count"], unit: "count" }
          ],
          label: "Camera lens"
        }
      }
    ]
  },

  "tfgm-atc": {
    label: "TfGM Automatic Traffic Counter",
    disciplines: ["transport"],
    type: "Automatic Traffic Counter",
    duplicate: [
      { id: "n", label: "northbound" },
      { id: "s", label: "southbound" },
      { id: "e", label: "eastbound" },
      { id: "w", label: "westbount" },
      { id: "ne", label: "northeast bound" },
      { id: "nw", label: "northwest bound" },
      { id: "se", label: "southeast bound" },
      { id: "sw", label: "southwest bound" }
    ],
    sensors: [
      {
        "camera_lens": {
          timeInterval: "5 mins",
          observables: [
            { id: "vehicle-count", qualifier: { id: "mcl", label: "Motorbikes" }, disciplines: ["transport"], usedProcedures: ["count"], unit: "count" },
            { id: "vehicle-count", qualifier: { id: "car", label: "Car" }, disciplines: ["transport"], usedProcedures: ["count"], unit: "count" },
            { id: "vehicle-count", qualifier: { id: "car+t", label: "Cars + trolley" }, disciplines: ["transport"], usedProcedures: ["count"], unit: "count" },
            { id: "vehicle-count", qualifier: { id: "rigid", label: "Rigids" }, disciplines: ["transport"], usedProcedures: ["count"], unit: "count" },
            { id: "vehicle-count", qualifier: { id: "artic", label: "Articulated" }, disciplines: ["transport"], usedProcedures: ["count"], unit: "count" },
            { id: "vehicle-count", qualifier: { id: "bus", label: "Buses" }, disciplines: ["transport"], usedProcedures: ["count"], unit: "count" },
            { id: "vehicle-speed", qualifier: { id: "avgspeed", label: "Average Speed" }, disciplines: ["transport"], usedProcedures: ["count"], unit: "count" }
          ],
          label: "Camera lens"
        }
      }
    ]
  }
}

export default DevInfo