import React from 'react'

import classes from './FAQs.module.css'

class FAQs extends React.Component {

  constructor(props) {
    super()
    this.state = {
      faqs: [
        {
          question: "What is the goal of Manchester-i?",
          answer: "Manchester-i aims to host data pertaining to the urban environment " +
          "in Greater Manchester to improve the understanding and decision-making. " +
          "It forms part of the <a target='_blank' href='https://www.urbanobservatory.manchester.ac.uk/'>" +
          "Manchester Urban Observatory</a> and provides a facility to " +
          "host relevant data from other organisations, as well as to open up data from " +
          "research projects to the public.",
          visible : false
        },
        {
          question: "Who funds Manchester-i?",
          answer: "Manchester-i is part funded by the government through a research " +
          "council grant as part of a group of 6 urban observatories across the UK. " +
          "We also get income from other work primarily funded by research councils, " +
          "government organisations and private companies. The main project website " +
          "has more details.",
          visible : false
        },
        {
          question: "Who runs Manchester-i?",
          answer: "Manchester-i is based at the University of Manchester and run by " +
          "researchers from the science and humanities faculties.",
          visible : false
        },
        {
          question: "Who owns the data?",
          answer: "Lots of different organisations own different sensors and thus " +
          "control data. They have different ways of storing the data and different " +
          "rules about sharing the data. One of the key services Manchester-i provides " +
          "is to help organisations share their data and access relevant data from other " +
          "organisations in an easy and open way.",
          visible : false
        },
        {
          question: "Who owns the sensors?",
          answer: "A wide range of stakeholders own the sensors themselves, from government " +
          "bodies to private companies, community organisations and research projects. This " +
          "is what makes it hard to capture all the data that exists for the city. For example, " +
          "the river level sensors are owned and managed by DEFRA.",
          visible : false
        },
        {
          question: "Why is there no data for some sensors?",
          answer: "Malfunctions, offline external data platforms, problems with software " +
          "updates are common for traffic and air quality sensors. We will work hard to " +
          "identify and fix any problems as soon as possible."
        },
        {
          question: "How accurate is the data presented on Manchester-i?",
          answer: "Data accuracy is of paramount importance to the Manchester Urban " +
          "Observatory team. Manchester-i presents live data from a vast array of sensors " +
          "using a large range of technologies, but we are not always the data providers " +
          "and are only hosting relevant data sources.  <br />" +
          "Whilst we do our very best to provide accurate data in real time from our own " +
          "sensors, it can be very challenging to provide final and verified data live. " +
          "At various intervals within specific projects updated calibrations may be applied " +
          "to the data, giving new results. We aim to do this as regularly as possible, but " +
          "please note that the live data is not final data and is liable to change."
        },
        {
          question: "Some of the readings look odd - why is that?",
          answer: "Malfunction of sensors is common. Metadata that includes information " +
          "on instrument performance (not hosted on the site) can inform the Manchester " +
          "Urban Observatory team when there is a sensor problem and inaccuracies are likely. " +
          "It is at this point that we take the necessary action to resolve these problems."
        },
        {
          question: "What are the legal limits for air pollution?",
          answer: "The UK legal limits for air pollution are described in this <a href= " +
          "'https://uk-air.defra.gov.uk/assets/documents/Air_Quality_Objectives_Update.pdf' " +
          "target= '_blank'>DEFRA document</a> noting that the units in this document are " +
          "not always the same as presented on the Manchester-i site."
        },
        {
          question: "What is the effect of weather conditions on local air quality?",
          answer: "The weather has an important effect on air pollution levels. During " +
          "high pressure systems, the air is usually still which allows pollution levels " +
          "to build up but during low pressure systems the weather is often wet and windy, " +
          "causing pollutants to be dispersed or washed out of the atmosphere by rain. " +
          "Weather can make it hard to interpret air quality data, especially in isolation, " +
          "but can be taken into account when analysing air quality levels."
        },
        {
          question: "Why is calibration important?",
          answer: "Air quality sensors require regular calibration to ensure that they are " +
          "providing accurate readings. The sensors that are managed by the Manchester Urban " +
          "Observatory have been tested and are regularly calibrated against scientific grade " +
          "sensors at the <a target='_blank' " +
          "href='https://www.manchester.ac.uk/discover/news/2m-invested-in-tackling-air-pollution-in-greater-manchester/'>" +
          "NERC-DEFRA funded supersite in South Manchester</a>."
        },
        {
          question: "Why do some sensors provide more information than others?",
          answer: "This is because there are a range of different sensors used to capture " +
          "things like traffic and air quality. For example, some traffic sensors focus on " +
          "vehicular traffic, while others capture vehicular traffic and pedestrians. Some " +
          "sensors can also be posted at junctions to capture 4-way traffic flows, so can " +
          "produce a large number of data feeds."
        },
        {
          question: "I want more data! Where can I get it?",
          answer: "There are numerous different portals hosting data. Check out our sister " +
          "observatories at <a target='_blank' href='https://newcastle.urbanobservatory.ac.uk/'>" +
          "Newcastle</a> and <a target='_blank' href='https://data.birminghamurbanobservatory.com/map/platforms'>" +
          "Birmingham</a>. Relevant national and local data sources include the <a target='_blank' href=" +
          "'https://uk-air.defra.gov.uk/data/'>DEFRA air quality archive</a> and the new <a target='_blank' " +
          "href='https://dashboards.instantatlas.com/viewer/report?appid=b0aa98ed7113440581b4b3513ebb6e3d'>" +
          "Manchester Intelligence Hub</a> respectively."
        },
        {
          question: "Want to request / share data? Want to give us feedback / work together?",
          answer: "Drop us a line at <a href='mailto:muo@manchester.ac.uk'>muo@manchester.ac.uk</a>, " +
          "we are always very happy to discuss data and opportunities to work together."
        }
      ] 
    }
  }


  toggleFAQVisibility = async e => {
    const faqs = this.state.faqs
    faqs[e.target.id].visible = !faqs[e.target.id].visible
    console.log(faqs)
    await this.setState({faqs})
  }


  render() {
    let faqs = this.state.faqs.map( (faq, index) => {
      return(
        <div key={index} style={{display:"flex", flexDirection: "column"}}>
          <div key={index} style={{display:"flex", flexDirection: "row", alignItems: "center"}}>
          <div 
          id = {index} 
          className={classes.question} 
          onClick={e => this.toggleFAQVisibility(e)}
          dangerouslySetInnerHTML={{ __html: faq.question }}
          /> 
          <div className={this.state.faqs[index].visible ? classes.arrowDown : classes.arrowRight} />
          
          </div>
          <div 
          className={this.state.faqs[index].visible? classes.answerShow : classes.answerHide} 
          dangerouslySetInnerHTML={{ __html: faq.answer }}
          />
        </div>
      )
    })
    return(
      <div className={classes.pageContent}>
        <h1>Frequently Asked Questions</h1>
        <div className={classes.mainContainer}>
          {faqs}
        </div>
        <div style={{height: "4rem"}} />
      </div>
    )
  }

}

export default FAQs