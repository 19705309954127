import React from 'react';
import classes from './PrivacyPolicy.module.css';
 
 
const PrivacyPolicy  = props => {

  return(
    <div className={classes.pageContent}>
      <h1>Privacy Policy</h1>
      <div className = {classes.tc}>
        <p>The only information that Manchester-i collects about its users consist 
          of the details entered at the time of signing up (i.e. name, surname, 
          email address and - optionally - affiliation).</p>
        <p>This information is not shared with anyone, and its sole purpose is to 
          allow registered users to access password protected data.</p>
        <p>In the near future Manchester-i will provide users with the possibility 
          to customise their preferences, such as dashboards views or news feeds. 
          This will entail the use of some coockies intended to persist those 
          preferences for a seamless user experience.</p>
        <p>Your data is used only for the purpose of improving our services and 
          your user experience with Manchester-I, and will not be given, sold or 
          shared with any third party.</p>
        <p>We may use your information to contact you in regard to changes in our 
          services or policies.</p>
      </div>
    </div>
  );

}

export default PrivacyPolicy;