import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import classes from "./UserAccount.module.css";
import Separator from "../../UI/Separator/separator";
import AuthContext from "../../store/auth-context";
import EditUserDataForm from "../../UI/EditUserDataForm/EditUserDataForm";


const UserAccount = (props) => {

	const authCtx = useContext(AuthContext)
	const isLoggedIn = authCtx.isLoggedIn
	const token = authCtx.token

	const history = useHistory()

	const base_url = `https://muo-backend.cs.man.ac.uk`;

	const [showForm, setShowForm] = useState(false)
	const [user, setUser] = useState({ firstName: "", lastName: "" })
	const [updateError, setUpdateError] = useState(false)
	const [updDone, setUpdDone] = useState(false)


	useEffect(async () => {
		const config = {
			headers: {
				Authorization: "Bearer " + token,
			},
		};
		try {
			const response = await axios.get(`${base_url}/users/me`, config);
			const user = response.data;
			await setUser(user)
		} catch (e) {
			console.log("Unable to retrieve user profile");
		}
	}, [])


	const showPsswd = () => {
		setShowForm(true);
	};

	const hidePsswd = () => {
		setShowForm(false)
	};


	const submitHandler = async (newPassword) => {
		try {
			await axios.patch(
				`https://muo-backend.cs.man.ac.uk/users/me`,
				{ password: newPassword },
				{ headers: { Authorization: "Bearer " + token } }
			);
			await setUpdateError(false)
			await setUpdDone(true)
		} catch (e) {
			console.log(e);
			await setUpdateError(true);
		}
	}

	let updateError1 = updateError 
		?  <p style={{ color: "darkRed" }}>{"-- Unable to update password --"}</p>
		: ''

	let updDone1 = updDone 
		?  <p style={{ color: "darkGreen" }}>{"-- Password updated --"}</p>
		: ''

	return (
		<div className={classes.pageContent} >

			{!isLoggedIn && history.replace({ pathname: `/authenticate` })}

			<div
				style={{
					display: "flex",
					flexFlow: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<img
					src={require("../../imgs/user.png")}
					alt=""
					style={{ display: "block", width: "5.5rem", height: "5.5rem" }}
				/>
				<div
					style={{
						display: "block",
						verticalAlign: "middle",
						margin: "0px 30px",
					}}
				>
					<p
						align="left"
						style={{
							fontSize: "2.2rem",
							display: "block",
							verticalAlign: "middle",
							fontFamily: "Open Sans",
							fontWeight: "400",
							marginTop: "0.5rem",
						}}
					>
						{user
							? user.firstName + " " + user.lastName
							: ""}
						<br />
						<span style={{ fontSize: "1.4rem", color: "#777" }}>
							{user ? user.affiliation : ""}
						</span>
					</p>
				</div>
			</div>
			<br />
			<p
				style={{ display: "block", margin: "auto", cursor: "pointer" }}
				onClick={showForm? hidePsswd : showPsswd}
			>
				{showForm ? "Cancel" : "Change Password"}
				<Separator orientation="v" size="30" />
			</p>
			{showForm && <EditUserDataForm onSubmit={submitHandler} />}

			{updateError1}
			{updDone1}

		</div>
	);
}


// export default connect(mapStateToProps, mapDispatchToProps)(UserAccount);
export default UserAccount
