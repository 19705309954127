import React from "react";
import axios from "axios";
import classes from "../lbn/lbn.module.css";
import Separator from "../../UI/Separator/separator";
import QueryControlsStateless from "../../UI/QueryControls/queryControlsStateless";
import { Button } from "glamorous";
import Card from "../../UI/Card/Card";
import TimeSeries from "../../Charts/TimeSeries";

class PollenProject extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
      platform: null,
      location: null,
      querySettings: {
        start_time: new Date(new Date().setDate(new Date().getDate() - 1)), 
        end_time: new Date(), 
        resolution: "1h",
        interpolate: false
      }
		};
		this.surveyLink = React.createRef();
	}

	//========== GLOBAL VARIABLES ==========

  base_url = `https://muo-backend.cs.man.ac.uk`
	// base_url = "https://muo-backend.its.manchester.ac.uk"; // On premise (UoM)
	// base_url = "http://localhost:1000";

	spinner = (
		<img
      alt=""
			src={require("../../imgs/spinner.svg")}
			style={{ width: "4.5rem", margin: "2rem auto" }}
		/>
	);

  dpl_id = "pollen_detection"
  plt_id = "swisens_poleno_6"
  sns_id = "swisens_poleno_6__sensor"
  tms_ids =[
    "swisens_poleno_6__grass-pollen-concentration",
    "swisens_poleno_6__trees-pollen-concentration",
    "swisens_poleno_6__pollen-load"
  ]

	
	async componentDidMount() {
		await this.getPlatform()
    await this.getPlatformLocation()
    await this.getSensorsFromPlatform()
    await this.fetchData()
	}

	//============== QUERIES ===============


	// GET pollen platform'
	async getPlatform() {
		const res = await axios.get(`${this.base_url}/platformsmongo/${this.plt_id}`);
		const platform = res.data;
		await this.setState({ platform });
	}

	// GET the locations of the platforms listed in 'platforms_ids'
	async getPlatformLocation() {
		const res = await axios.post(`${this.base_url}/locationsmongo`, {
			ids: [this.plt_id],
		});
    let platLoc = res.data[0]
    let location = platLoc.locations.filter((loc) => {
      return loc.inDeployment === this.dpl_id;
    })[0];
    await this.setState({ location });
	}

	// GET list of sensors belonging to selected platform
	async getSensorsFromPlatform() {
		let response = await axios.post(`${this.base_url}/timeseriesmongo`, {
			madeBySensor: this.sns_id,
		});
		const timeseries = response.data
    this.setState({timeseries})
	}

	// GET data for timeseries
	async fetchData(e) {
    await this.setState({data: null})
		let data = [];
		// For each time series...
		for (let i in this.state.timeseries) {
      const ts = this.state.timeseries[i]
	
			// ... query for raw data with the selected parameters settings
			try {
				const res = await axios.get(`${this.base_url}/rawdata`, {
					params: {
						ref: ts.id,
            start_time: this.state.querySettings.start_time.toISOString(),
						end_time: this.state.querySettings.end_time.toISOString(),
						resolution: this.state.querySettings.resolution,
						interpolate: this.state.querySettings.interpolate,
						quantity: ts.observedProperty["@id"],
					},
				});
				let ts1 = {};
				ts1.name = `${ts.observedProperty.label} ${
					ts.comment ? " - " + ts.comment : ""
				} ${ts.unit.symbol ? '('+ts.unit.symbol+')' : ""}`
				ts1.data = [];
				for (let p in res.data) {
					const key = res.data[p].time
					const value = res.data[p].value === null ? null : Math.round(res.data[p].value * 100) / 100
					ts1.data.push({x: key, y: value})
				}
				data.push(ts1);
			} catch (e) {
        console.log(e.message)
			}
		}
    await this.setState({ data })
	}


	//======================================

	//========= OTHER FUNCTIONS ============

	setQueryControls = async (e) => {
		await this.setState({ querySettings: e })
	};


	//======================================

	render() {
		let intro = (
			<div
				className={classes.box}
				style={{
					margin: "0rem auto",
					textAlign: "justify",
					maxWidth: "65rem",
					fontFamily: "'Open Sans', sans-serif",
					fontSize: "1.1rem",
					fontWeight: "400",
					lineHeight: "1.7rem",
					padding: "0.5rem 2rem",
					backgroundColor: "rgb(255,225,129)",
				}}
			>
				{" "}

				<p>
          <b>Manchester-i</b> now provides the UK’s first real-time pollen count city-data solution.
          Users can access the live tree and grass pollen counts below and make better informed
          decisions about their exposure to those biological allergens, taking precautions
          to mitigate health impacts when necessary. The potential allergenic impact is summarised
          as the total pollen load in the third graph, which takes into account the allergenic
          efficacy of each pollen species detected as certain species can elicit a much stronger
          allergic response than others.
        </p>
        <p>
          By default, pollen counts and the resulting allergenic load from the last 24 hours are
          displayed below at hourly intervals. Users can change the time range and resolution 
          in the boxes above the graphs to suit their needs.
        </p>
				
			</div>
		);

		let plots = []

    plots = this.state.data ? 
    this.state.data.map((timeseries, index) => {
			return (
				<div className={classes.TimeSeriesCard}>
					<Card
						key={index}
						title={timeseries.name}
						content={

							<TimeSeries
								key={index}
								series={timeseries}
								display={true}
								cumulative={timeseries.cumulative}
								type="line"
								height="20rem"
							/>
						}
            footer = {index === 2 ? '0-1 = low, 1-2 = medium, 2-3 = high, 3+ = very high' : null}
					></Card>
				</div>
			);
    })
    : <div>{this.spinner}<Separator orientation="v" size={100} /></div>;
	

		const timeSelectAndDownload = this.state.location ? (
			<div>
				<QueryControlsStateless

          start_time={this.state.querySettings.start_time}
          end_time={this.state.querySettings.end_time}
          resolution={this.state.querySettings
                ? this.state.querySettings.resolution
                : "1h"}
					onChange={(e) => this.setQueryControls(e)}
				></QueryControlsStateless>
        <Separator orientation='v' size='10'/>

				<Button
					onClick={(e) => this.fetchData(e)}
					disabled={
						this.state.querySettings
							? this.state.querySettings.resolution
								? !this.state.loadingData
									? false
									: true
								: true
							: true
					}
				>
					{this.state.loadingData ? "Fetching data..." : "Refresh Plots"}
				</Button>
				<br />
				{this.state.loadingData ? this.spinner : ""}
				<Separator orientation="v" size={20} />
			</div>
		) : (
			<div></div>
		);

		return (
			<div style={{paddingTop: "3rem"}}>
        {intro}
        <Separator orientation='v' size='40'/>
        {timeSelectAndDownload}
        {plots}
      </div>
    )
  }
}

export default PollenProject;
