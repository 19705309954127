import React, { useState, useEffect, Fragment, useContext } from "react";

import axios from "axios";
import { useHistory } from "react-router-dom";

import MetaTags from "react-meta-tags";
import Mapi from "../../UI/Map/map";
import AuthContext from "../../store/auth-context";

import * as gv from "../../global_variables";
import classes from "./Deployments.module.css";


const FormData = require('form-data');

const Crowdsourcingtwo = props => {

  const authCtx = useContext(AuthContext)
  const isLoggedIn = authCtx.isLoggedIn
  const token = authCtx.token

  const history = useHistory()

  const [pinLocation, setPinLocation] = useState(null)
  const [showPin, setShowPin] = useState(false)
  const [selectedAnswer, setSelectedAnswer] = useState(-1)
  const [selectedOption, setSelectedOption] = useState(-1)
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [surveyCompleted, setSurveyCompleted] = useState(false)
  const [instructions, setInstructions] = useState(true)
  const [metrics, setMetrics] = useState([])
  const [textbox, setTextbox] = useState(false)
  const [comment, setComment] = useState(null)
  const [optionContent, setOptionContent] = useState(null)
  const [viewResults, setViewResults] = useState(false)
  const [windowW, setWindowW] = useState(window.innerWidth)
  const [windowH, setWindowH] = useState(window.innerHeight)
  const [image, setImage] = useState(undefined)
  const [user, setUser] = useState(null)
  const [freezeMap, setFreezeMap] = useState(false)



  //========== GLOBAL VARIABLES ==========


  const questionMark = (
    <img
      alt=""
      src={require("../../imgs/info_map.svg")}
      className={classes.questionMark}
    />
  )


  const imgs = {
    check: require("../../imgs/check.svg"),
  };

  let application = "hys";

  //======================================

  useEffect(() => {

    // Handler to call on window resize
    function handleResize() {
      setWindowW(window.innerWidth)
      setWindowH(window.innerHeight)
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    getPerceptualMetrics()

    getUser()

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);

  }, []);


  const getUser = async () => {
    const config = {
      headers: {
        Authorization: "Bearer " + token
      }
    }

    try {
      const res = await axios.get(`${gv.backend_url}/users/me`, config);
      const user = res.data;
      setUser(user);
    } catch (e) {
      console.log("Unable to retrieve user profile");
    }
  }


  // Get and sort the questions associated with the given application
  const getPerceptualMetrics = async () => {
    const res = await axios.get(`${gv.backend_url}/perc_metrics/${application}`)
    const metrics = res.data.sort((a, b) => {
      const metric1 = a.applications.filter(
        (app) => app.name === application
      )[0];
      const metric2 = b.applications.filter(
        (app) => app.name === application
      )[0];
      if (metric1.order < metric2.order) {
        return -1;
      } else {
        return 1;
      }
    })
    setMetrics(metrics)
  }


  const onMapClick = async (latLng) => {
    if (!showPin) {
      setPinLocation(latLng)
      setShowPin(true)
      setCurrentQuestion(0)
      setSelectedAnswer(-1)
      setSelectedOption(-1)
      setSurveyCompleted(false)
      setInstructions(false)
      setFreezeMap(true)
    }
  }


  const handleClose = async (e) => {
    setShowPin(false)
    setCurrentQuestion(0)
    setTextbox(false)
    setFreezeMap(false)
    setSelectedAnswer(-1)
    setSelectedOption(-1)
  }


  const onPostcodeFound = async (e) => {
    setShowPin(false)
    setCurrentQuestion(0)
    setTextbox(false)
    setCurrentQuestion(0)
    setSelectedAnswer(-1)
    setSelectedOption(-1)
    setSurveyCompleted(false)
    setInstructions(false)
    setFreezeMap(false)
  }


  const handleCloseInstructionBox = async (e) => {
    setInstructions(false)
  }

  const showInstructionHandler = async (e) => {
    setInstructions(true)
  }


  const handleNext = async (e) => {

    if (selectedAnswer > 0 && e.target.id === "confirm") {

      // Post answer
      const perception = {
        metric_id: metrics[currentQuestion].id,
        value:
          metrics[currentQuestion].answers[selectedAnswer - 1].value,
        location: {
          latitude: pinLocation[0],
          longitude: pinLocation[1],
        },
        timestamp: new Date(),
        comment: comment,
        user_id: user ? user[`_id`] : 'genericuser'
      };

      console.log(perception)

      try {
        const ppp = await axios.post(`${gv.backend_url}/perception`, perception)

        if (image) {
          await uploadImage(ppp.data['_id'], image)
          setImage(undefined)
        }

      } catch (e) {
        console.log(e.message);
      }
    }

    await setSelectedAnswer(-1)
    await setSelectedOption(-1)
    await setCurrentQuestion(currentQuestion < metrics.length - 1 ? currentQuestion + 1 : currentQuestion)
    await setSurveyCompleted(currentQuestion === metrics.length - 1 ? true : false)
    await setTextbox(false)
    await setComment('')
    await setWindowW(window.innerWidth)
    await setWindowH(window.innerHeight)
  };

  const uploadImage = async (perceptionId, imageFile) => {

    var formData = new FormData();
    formData.append("image", imageFile);
    axios.post(`${gv.backend_url}/perception/${perceptionId}/image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }


  const handleSelect = async (e) => {
    const selectedIndex = e.target.selectedIndex
    setSelectedAnswer(selectedIndex)
    setSelectedOption(-1)
    setTextbox(false)
  };


  const handleSelectOption = async (e) => {
    const selectedIndex = e.target.selectedIndex
    const textbox =
      selectedIndex ?
        metrics[currentQuestion].answers[
          selectedAnswer - 1
        ].options[selectedIndex - 1].toLowerCase() === "other"
          ? true
          : true // Always visible
        : false
    setTextbox(textbox)
    setSelectedOption(selectedIndex)
    setComment(e.target.value)
    setOptionContent(e.target.value)
  };



  const handleTextInput = async (e) => {
    setComment(e.target.value)
  };


  const redirectToResults = async (e) => {
    setViewResults(true)
  };


  const handleCapture = async (event) => {
    const image = event.target.files[0]
    setImage(image)
  }


  const Instructions = (
    <div
      className={classes.instructions}
      style={{
        display: instructions ? "" : "none",
        padding: windowH / 20,
        fontSize: "medium",
        textAlign: "left",
        width: "calc(min(80%, 30rem)"
      }}
    >
      Hello! We are collecting opinions and ideas from members of the public about the areas of the city where they live or that they know.
      <br /><br />
      Please, tap on the map to select a location you want to comment on, and take the short survey (3 questions).
      <br />
      <br />
      To view the answers provided so far, go to the{" "}
      <span
      style={{color: "#008ABD", cursor: "pointer"}}
        onClick={() => redirectToResults()}
      >
        results map
      </span>{" "}
      and select the question you are interested in.
      <div
        style={{
          position: "absolute",
          top: "0rem",
          right: "1rem",
          fontSize: "2rem",
          color: "#bbb",
          fontWeight: "lighter",
          cursor: "default",
        }}
        onClick={() => handleCloseInstructionBox()}
      >
        ×
      </div>
    </div>
  );



  const info = (
    <div
      className={classes.info}
      onClick={() => showInstructionHandler()}
    >
      {questionMark}
    </div>
  );



  let options = [
    <option
      key={-1}
      id={-1}
      label="Select..."
      value=""
      selected={selectedAnswer === -1 ? "selected" : ""}
    />,
  ];

  if (metrics.length) {
    metrics[currentQuestion].answers.forEach(
      (answer, index) => {
        options.push(
          <option
            key={index}
            id={answer.value}
            name={answer.text}
            value={answer.value}
            label={answer.text}
            selected={
              selectedAnswer - 1 === index ? "selected" : ""
            }
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {answer.text}
          </option>
        );
      }
    );
  }



  let options1 = [
    <option
      key={-1}
      id={-1}
      label="Select..."
      value=""
      selected={selectedAnswer === -1 ? "selected" : ""}
    />,
  ];

  if (metrics.length && selectedAnswer > 0) {
    metrics[currentQuestion].answers[selectedAnswer - 1].options.forEach(
      (option, index) => {
        options1.push(
          <option
            key={index}
            id={option}
            name={option}
            value={option}
            label={option}
            selected={
              selectedOption - 1 === index ? "selected" : ""
            }
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {option}
          </option>
        );
      }
    );
  }


  let questionnaire = (
    <div
      className={
        showPin ? classes.questionnaireUp : classes.questionnaire
      }
      style={{
        borderTop: surveyCompleted
          ? "0px"
          : metrics.length > 0
            ? metrics[
              currentQuestion
            ].question_type.toUpperCase() === "PROBLEM"
              ? "1rem solid rgba(220,130,130, 1)"
              : metrics[
                currentQuestion
              ].question_type.toUpperCase() === "SOLUTION"
                ? "1rem solid rgb(220,160,110)"
                : metrics[
                  currentQuestion
                ].question_type.toUpperCase() === "ACTION"
                  ? "1rem solid rgb(70,190,80)"
                  : "white"
            : "white",
      }}
    >
      {surveyCompleted ? (
        <div className={classes.questionBox}>
          <img alt="" src={imgs.check} className={classes.questionIcon} />
          <div className={classes.question}>
            Thank you for completing the survey!
          </div>
          <div style={{ height: "1rem", display: "block" }}></div>

          <div className={classes.buttonSection}>
            <div
              style={{
                padding: "0.2rem 0.5rem",
                borderRadius: "0.3rem",
                backgroundColor: "grey",
                color: "white",
                cursor: "default",
              }}
              onClick={() => handleClose()}
            >
              Close
            </div>
            <div style={{ display: "flex", flexGrow: "1" }}></div>
            <div
              style={{
                padding: "0.2rem 0.5rem",
                borderRadius: "0.3rem",
                backgroundColor: "#229933",
                color: "white",
                cursor: "default",
              }}
              onClick={() => redirectToResults()}
            >
              View results
            </div>
          </div>
        </div>
      ) : metrics.length ? (
        <div>
          {/* <div
						style={{
							fontSize: "1rem",
							borderBottom: "1px solid grey",
							color: "grey",
							paddingBottom: "0.5rem",
							width: "80%",
							margin: "auto",
						}}
					>
						{metrics[
							currentQuestion
						].theme.toUpperCase()}
					</div> */}
          <div className={classes.questionBox}>
            <div className={classes.question}>
              {metrics[currentQuestion].question}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div style={{ display: "block", height: "1.2rem" }} />

      {surveyCompleted ? (
        ""
      ) : (
        <div>
          <select
            className={classes.select}
            onChange={handleSelect}
          >
            {options}
          </select>

          <select
            className={classes.select}
            style={{
              visibility: selectedAnswer > 0 ? "visible" : "hidden"
            }}
            onChange={handleSelectOption}
          >
            {options1}
          </select>

          <input
            key={currentQuestion}
            className={classes.textBox}
            style={{
              visibility: textbox && windowW > 1000 && optionContent === 'other' ? "visible" : "hidden",
              margin: textbox && windowW > 1000 ? "1rem 0rem 1rem 0rem" : "0rem",
              width: "100%",
              boxSizing: "border-box"
            }}
            placeholder="Write something..."
            onChange={handleTextInput}
            value={undefined}
          ></input>

          <label for="icon-button-file" style={{ cursor: "pointer", visibility: textbox ? "visible" : "hidden" }}>
            {image ?
              <img alt="" src={URL.createObjectURL(image)} width="150px" />
              :
              <img alt="" src={require('../../imgs/camera.svg')} width="50px" />
            }
          </label>
          <input accept="image/*" id="icon-button-file" type="file" capture="environment" style={{
            opacity: 0,
            position: "absolute",
            zIndex: -1
          }}
            onChange={handleCapture} />
        </div>
      )}

      <div
        style={{
          position: "absolute",
          top: "0%",
          right: "3%",
          fontSize: "2rem",
          color: "#bbb",
          fontWeight: "lighter",
          cursor: "default",
        }}
        onClick={() => handleClose()}
      >
        ×
      </div>
      {surveyCompleted ? (
        ""
      ) : (
        <div className={classes.buttonSection}>
          <div
            style={{
              padding: "0.2rem 0.5rem",
              borderRadius: "0.3rem",
              backgroundColor: "grey",
              color: "white",
              cursor: "default",
            }}
            id="skip"
            onClick={(e) => handleNext(e)}
          >
            Skip
          </div>
          <div style={{ display: "flex", flexGrow: "1" }}></div>
          <div
            style={{
              padding: "0.2rem 0.5rem",
              borderRadius: "0.3rem",
              backgroundColor:
                selectedAnswer !== -1 ? "#229933" : "lightgrey",
              color: "white",
              cursor: "default",
            }}
            id="confirm"
            onClick={
              selectedAnswer !== -1
                ? (e) => handleNext(e)
                : null
            }
          >
            Confirm
          </div>
        </div>
      )}
    </div>
  );

  //==============================

  return (

    <Fragment>
      {!isLoggedIn && history.replace({ pathname: "/authenticate", provenance: "cstwo" })}
      {viewResults && history.replace({ pathname: "/pm" })}
      <MetaTags>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0"
        />
        <meta
          name="display"
          content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0"
        />
      </MetaTags>

      {/* {authRedirect} */}

      <Mapi
        showPinOnClick={true}
        freezeMap={freezeMap}
        onMapClick={onMapClick}
        onPostcodeFound={onPostcodeFound}
        mapStyle='mapbox://styles/mapbox/bright-v9'
        zoomOnClick={true}
        centerOnClick={true}
      />

      {info}
      {questionnaire}
      {Instructions}

    </Fragment>
  )
}

export default Crowdsourcingtwo
