import React, { useEffect } from 'react';
import classes from './App.module.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ScrollToTop from './scrollToTop';
import CookieConsent from "react-cookie-consent";
import Analytics from 'analytics'
import { AnalyticsProvider } from 'use-analytics'
import googleAnalytics from '@analytics/google-analytics'
import LandingLayout from './Layouts/LandingLayout';
import NavBarLayout from './Layouts/NavBarLayout';


const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      trackingId:  'UA-226377978-1'
    })
  ]
})

const App = props => {

  useEffect(() => {
    document.title = "Manchester-i";
  }, [])

    return (
      <AnalyticsProvider instance={analytics}>
      <BrowserRouter>
        <ScrollToTop>
          <div className={classes.App}>
            <Switch>
              <Route exact path="/" render={() => <LandingLayout /> } />   
              <Route path="/" render={() => <NavBarLayout /> } />        
            </Switch>
          </div>
        </ScrollToTop>
        <CookieConsent
        // enableDeclineButton
        // onDecline={() => {
        //   alert("nay!");
        // }}
        >
          This website only uses essential coockies. These allow users to remain logged-in to Manchester-i unless they explicitely log-out.
        </CookieConsent>
      </BrowserRouter>
      </AnalyticsProvider>
    );
  }


export default App